import React, { FC } from 'react';
import cx from 'classnames';
import { renderCellAmount, renderCellHash } from '@shared/ui';
import { ArrowLeftLongIcon } from '@rubin-dev/goblin';
import { AddressTransactionsFilterTxType } from '@apolloGenerated';
import styles from './styles.module.scss';

export type LineAmountCellProps = {
  source: string;
  target: string;
  amount: string | number;
  network: string;
  type: AddressTransactionsFilterTxType;
};
export const LineAmountCell: FC<LineAmountCellProps> = ({
  source,
  target,
  amount,
  type,
  network,
}) => {
  return (
    <div className={styles.betweenAmount}>
      {renderCellHash({ hash: source, type: 'address', network })}
      <ArrowLeftLongIcon
        className={cx(
          styles.betweenAmount__icon,
          type === AddressTransactionsFilterTxType.Sent && styles.sent,
        )}
      />
      <div className={styles.betweenAmount__value}>
        {renderCellAmount({ amount, network, type })}
      </div>
      <ArrowLeftLongIcon
        className={cx(
          styles.betweenAmount__icon,
          type === AddressTransactionsFilterTxType.Sent && styles.sent,
        )}
      />
      {renderCellHash({ hash: target, type: 'address', network })}
    </div>
  );
};

export const renderCellLineAmount = (args: LineAmountCellProps) => (
  <LineAmountCell {...args} />
);

import {
  AddressTransactionStats,
  Cluster,
  ExplorerClusterForAddressQuery,
  ExplorerClusterForAddressQueryVariables,
  GraphClusterQuery,
  GraphClusterQueryVariables,
  GraphRiskAddressByHashQuery,
  GraphRiskAddressByHashQueryVariables,
  GraphStatsAddressByHashQuery,
  GraphStatsAddressByHashQueryVariables,
  Maybe,
  Network,
  GraphStatsAddressByHashDocument,
  GraphRiskAddressByHashDocument,
  GraphClusterDocument,
  ExplorerClusterForAddressDocument,
  NodeCoords,
  AddressCheckedType,
} from '@apolloGenerated';
import { apolloClient } from '../../../../apollo';
import { NodeList } from './NodeList';
import { Node, NodeRiskArg } from './Node';
import { AddressTable } from '@graph/models';
import { TableType } from '@graph/types';
import { v4 as uuidv4 } from 'uuid';
import { RiskCategories } from '@graph/models/Risk/RiskCategories';

export type CreateNodeArgs = {
  hash: string;
  balance: string;
  counts: AddressTransactionStats;
  addressRisk: NodeRiskArg;
  cluster?: Maybe<Cluster>;
  type: TableType;
  checked?: AddressCheckedType[];
  coords?: NodeCoords;
};
export class NodeService {
  static async createClusterNode(
    wid: string,
    network: Network,
  ): Promise<Maybe<CreateNodeArgs>> {
    const riskCategories = RiskCategories.get(network);
    const { data } = await apolloClient
      .query<GraphClusterQuery, GraphClusterQueryVariables>({
        query: GraphClusterDocument,
        variables: { network, id: wid },
      })
      .catch((e) => e);
    if (!data) return null;
    const { totalAmount, sentCount, totalCount, receivedCount } =
      data.explorerClusterStats.stats!;
    const cluster = data.explorerCluster.cluster!;
    const riskCluster = riskCategories?.[cluster.riskCategory];

    return {
      hash: wid,
      balance: totalAmount,
      counts: { sent: sentCount, received: receivedCount, total: totalCount },
      addressRisk: {
        risk: riskCluster?.risk,
        categoryNumber: cluster.riskCategory,
      },
      cluster,
      type: TableType.Cluster,
    };
  }
  static async createAddressNode(
    address: string,
    network: Network,
  ): Promise<Maybe<CreateNodeArgs>> {
    const { data } = await apolloClient
      .query<GraphStatsAddressByHashQuery, GraphStatsAddressByHashQueryVariables>({
        query: GraphStatsAddressByHashDocument,
        variables: { network, address },
      })
      .catch((e) => e);
    const { data: riskData } = await apolloClient
      .query<GraphRiskAddressByHashQuery, GraphRiskAddressByHashQueryVariables>({
        query: GraphRiskAddressByHashDocument,
        variables: { network, address },
      })
      .catch((e) => e);
    const { data: clusterData } = await apolloClient
      .query<ExplorerClusterForAddressQuery, ExplorerClusterForAddressQueryVariables>({
        query: ExplorerClusterForAddressDocument,
        variables: { network, address },
      })
      .catch((e) => e);
    if (!data) return null;
    const {
      explorerAddressTransactionStats: { stats: countStats },
      explorerFindAddressByHash: { node: balanceStats },
    } = data;
    const addressRisk = riskData?.explorerRisk?.risk;
    return {
      hash: address,
      balance: balanceStats?.total!,
      counts: countStats!,
      addressRisk: {
        risk: addressRisk?.risk,
        categoryNumber: addressRisk?.reported?.number,
      },
      type: TableType.Address,
      cluster: clusterData?.explorerClusterForAddress?.cluster,
    };
  }

  static createNode(
    {
      hash,
      balance,
      counts,
      addressRisk,
      cluster,
      type,
      checked,
      coords,
    }: CreateNodeArgs,
    rewrite?: boolean,
  ) {
    NodeList.create(
      new Node(hash, uuidv4(), {
        balance,
        counts,
        risk: addressRisk,
        cluster,
        coords,
      }),
      new AddressTable(hash, type, checked),
      rewrite,
    );
  }

  static getNodeDataCreator(type: 'cluster' | 'address') {
    switch (type) {
      case 'address':
        return NodeService.createAddressNode;
      case 'cluster':
        return NodeService.createClusterNode;
    }
  }
}

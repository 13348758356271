import { downloadFile, getJsonFromFile } from '@utils/file';
import { DataConverter } from './DataConverter';
import {
  GraphByUuidDocument,
  GraphByUuidQuery,
  GraphByUuidQueryVariables,
  GraphData,
  GraphSaveData,
  GraphSaveDocument,
  GraphSaveMutation,
  GraphSaveMutationVariables,
  GraphUpdateDocument,
  GraphUpdateMutation,
  GraphUpdateMutationVariables,
} from '@apolloGenerated';
import { history, router, RouterName } from '../../../../router';
import { Meta } from '../Meta/Meta';
import { v4 as uuidv4 } from 'uuid';
import { apolloClient } from '../../../../apollo';
import { GraphApiErrors, GraphDataResult } from '../../types/graph';
import { getFormatDate } from '@utils/timestamp';
import createDataResult from '../../libs/helpers/createDataResult';

export class DataController {
  static async save(saveData: GraphSaveData): Promise<GraphDataResult<GraphSaveData>> {
    try {
      await apolloClient.mutate<GraphUpdateMutation, GraphUpdateMutationVariables>({
        mutation: GraphUpdateDocument,
        variables: {
          input: {
            uuid: Meta.uuid,
            data: saveData,
          },
        },
      });
      return createDataResult({ data: saveData });
    } catch (error) {
      if (error instanceof Error && this.hasGraphApiError(error.message))
        return createDataResult({ error: error.message });
      return createDataResult({ error: GraphApiErrors.SERVER_ERROR });
    }
  }
  static async getByUuid(uuid: string): Promise<GraphDataResult<GraphData>> {
    try {
      const { data } = await apolloClient.query<
        GraphByUuidQuery,
        GraphByUuidQueryVariables
      >({
        query: GraphByUuidDocument,
        variables: {
          uuid,
        },
      });
      return createDataResult({ data: data?.graphByUUID?.graph });
    } catch (error) {
      if (error instanceof Error && this.hasGraphApiError(error.message))
        return createDataResult({ error: error.message });
      return createDataResult({ error: GraphApiErrors.SERVER_ERROR });
    }
  }
  static async create(
    saveData: GraphSaveData,
    name?: string,
  ): Promise<GraphDataResult<{ uuid: string }>> {
    try {
      const graphName = name || saveData.meta.name;
      const uuid = uuidv4();
      const { data } = await apolloClient.mutate<
        GraphSaveMutation,
        GraphSaveMutationVariables
      >({
        mutation: GraphSaveDocument,
        variables: {
          input: {
            uuid,
            data: {
              ...saveData,
              meta: {
                ...saveData.meta,
                name: graphName,
              },
            },
          },
        },
      });
      Meta.graphName = graphName;
      return createDataResult({ data: { uuid: data?.graphSave?.uuid } });
    } catch (error) {
      if (error instanceof Error && this.hasGraphApiError(error.message))
        return createDataResult({ error: error.message });
      return createDataResult({ error: GraphApiErrors.SERVER_ERROR });
    }
  }

  static async duplicate(data: GraphSaveData) {
    const copiedName = `Copy of "${data.meta.name}"`;
    const createGraph = async (name: string) => {
      const { error } = await DataController.create(data, name);
      if (error?.code === GraphApiErrors.ALREADY_EXIST)
        await createGraph(`${copiedName} (${getFormatDate()})`);
    };
    await createGraph(copiedName);
  }

  static setData(uuid: string, data: object): GraphDataResult<GraphSaveData> {
    if (!DataConverter.validate(data))
      return createDataResult({ error: GraphApiErrors.INVALID_DATA });
    DataConverter.fillData(uuid, data);
    return createDataResult({ data });
  }

  static async importJSON(file: File): Promise<GraphDataResult<GraphSaveData>> {
    try {
      const data = await getJsonFromFile(file);
      if (!DataConverter.validate(data))
        return createDataResult({ error: GraphApiErrors.INVALID_DATA });
      const { error } = await DataController.create(
        data,
        `Imported (${getFormatDate()})`,
      );
      if (error) return createDataResult({ error: error.code });
      return createDataResult({ data });
    } catch {
      return createDataResult({ error: GraphApiErrors.INVALID_IMPORTED_DATA });
    }
  }

  static exportJSON(exportData: GraphSaveData): GraphDataResult<GraphSaveData> {
    if (!DataConverter.validate(exportData))
      return createDataResult({ error: GraphApiErrors.INVALID_DATA });
    downloadFile(JSON.stringify(exportData), exportData.meta.name, 'application/json');
    return createDataResult({ data: exportData });
  }

  private static hasGraphApiError(error: string): error is GraphApiErrors {
    return Object.values(GraphApiErrors).includes(error as GraphApiErrors);
  }

  static navigateToUuid(uuid: string) {
    Meta.uuid = uuid;
    history.navigate(
      router.urlFor(RouterName.ExplorerSavedVisualization, {
        uuid: uuid,
        network: Meta.network,
      }),
    );
  }
}

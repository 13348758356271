import React, { FC, PropsWithChildren, useLayoutEffect, useMemo } from 'react';
import { LayoutUser } from '@component/Layout';
import { ExplorerSearchScreen } from '../../../../screen';
import { ExplorerTabs, ExplorerTabsEnum } from '@component/explorer';
import { Container } from '@shared/ui';
import { Button, Snackbars, Spacer, Typography } from '@rubin-dev/goblin';
import styles from '../../../../root.module.scss';
import { useTranslation } from 'react-i18next';
import { useModal } from '@hooks/useModal';
import { useMeQuery } from '@apolloGenerated';
import cx from 'classnames';

type ExplorerLayoutProps = {
  params: {
    value: string;
    network: string;
  };
};

export const LayoutExplorer: FC<PropsWithChildren<ExplorerLayoutProps>> = ({
  children,
  params: { network, value },
}) => {
  const { t } = useTranslation();

  const { data } = useMeQuery();

  const expireDate = useMemo(
    () => data?.me.billing.analyzerSubscriptionExpireDate,
    [data?.me.billing.analyzerSubscriptionExpireDate],
  );

  const openTransactionModal = useModal('dialogTariffSelect');

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayoutUser>
      <ExplorerTabs initTab={ExplorerTabsEnum.Analyzer} />
      <ExplorerSearchScreen params={{ value, network }}>
        <Container>{children}</Container>
        <Spacer size={24} />
      </ExplorerSearchScreen>
      {!expireDate && data?.me && (
        <div className={cx(styles.container, styles.container_sticky)}>
          <Snackbars width={'100%'} className={styles.snackbar}>
            <div className={styles.content}>
              <Typography variant={'head-16'} color={'neutrals-1'}>
                {t('titles.youAreInBaseMode')}
              </Typography>
              <Button color={'secondary'} size={'small'} onClick={openTransactionModal}>
                {t('buttons.upgrade')}
              </Button>
            </div>
          </Snackbars>
        </div>
      )}
    </LayoutUser>
  );
};

import React, { FC } from 'react';
import { EventsController } from '@graph-visualization-module/features/Event';
import {
  VisualizationView,
  VisualizationViewProps,
} from '@graph-visualization-module/widget/View';
import { ContextMenu } from '@graph-visualization-module/widget/ContextMenu';

export const Visualization: FC<VisualizationViewProps> = ({ nodes, links, canvas }) => {
  return (
    <EventsController>
      <VisualizationView nodes={nodes} links={links} canvas={canvas} />
      {/*<ContextMenu />*/}
    </EventsController>
  );
};

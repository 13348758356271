import React, { FC, useContext, useMemo } from 'react';
import { AppError } from '../../component';
import { CategoryGroup } from '@models/risk';
import { useTranslation } from 'react-i18next';
import sdk from '@aml/sdk';
import { Loader, LoaderOverlay, Spacer, Table, Typography } from '@rubin-dev/goblin';
import { Container } from '@shared/ui';
import { AppContext } from '../../contexts';
import styles from './styles.module.scss';
import { RiskDot } from '@component/NewRisk';

export type CategoryAllListScreenProps = {
  categoryGroup?: CategoryGroup;
};

export const CategoryAllListScreen: FC<CategoryAllListScreenProps> = () => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
  const {
    data: categoryDict,
    isLoading,
    error,
  } = sdk.risk.BTC.queries.useRiskDictServiceCurrentDict();

  const headers = [
    { title: t('links.category'), value: 'name' },
    { title: t('strings.riskscore'), value: 'risk', width: '120px' },
    { title: t('mention.description'), value: 'description', width: '55%' },
  ];

  const categoryList = useMemo(
    () =>
      (categoryDict ? Object.values(categoryDict.params || {}) : []).sort(
        (curItem, nextItem) => (curItem.name || '').localeCompare(nextItem.name || ''),
      ),
    [categoryDict],
  );

  const items = useMemo(
    () =>
      categoryList.map(({ name, color, risk, i18n }) => ({
        name: (
          <div className={styles.category__dotItem}>
            <div className={styles.category__dot} style={{ background: color }} />
            <Typography variant="body-14" color="neutrals-8">
              {name}
            </Typography>
          </div>
        ),
        risk: (
          <div className={styles.category__dotItem}>
            <RiskDot risk={Number(risk || 0)} />
            <Typography variant="body-14" color="neutrals-8">
              {risk || 0}%
            </Typography>
          </div>
        ),
        description: (language === 'ru' ? i18n?.ru : i18n?.en) || '-',
      })),
    [categoryList],
  );

  if (isLoading) return <LoaderOverlay />;
  if (!items?.length || error) return <AppError error={t('errors.reloading')} />;
  return (
    <Container>
      <Table items={items} headers={headers} />
      <Spacer size={16} />
    </Container>
  );
};

import { Route } from 'react-justanother-router';
import * as screens from '../screen';
import {
  LayoutClean,
  LayoutUser,
  LayoutUserCreate,
  LayoutGraph,
  LayoutTransactions,
  LayoutExplorerAddress,
  LayoutExplorerWallet,
  LayoutExplorerTransaction,
  LayoutExplorerBlock,
  LayoutExplorerBlockTransaction,
  LayoutProfile,
  LayoutTariff,
  LayoutMarkup,
  LayoutExplorer,
  LayoutExplorerGraphs,
} from '@component/Layout';
import { RouterName } from './types';

const defaultProps = {
  layout: LayoutUser,
};

const cleanProps = {
  layout: LayoutClean,
};

const authProps = {
  ...defaultProps,
  auth: true,
};

const cleanLoginProps = {
  ...cleanProps,
  login: true,
};

export const routes: Route[] = [
  {
    path: '/',
    name: RouterName.HomePage,
    component: screens.HomepageScreen,
    props: defaultProps,
  },
  {
    path: '/markup',
    name: RouterName.Markup,
    component: screens.MarkupScreen,
    props: {
      layout: LayoutMarkup,
      translate: 'titles.markup',
    },
    children: [
      {
        path: '/details',
        name: RouterName.MarkupDetails,
        component: screens.DetailsScreen,
        props: {
          layout: LayoutMarkup,
        },
      },
    ],
  },
  {
    path: '/explorer',
    name: RouterName.HomePage,
    component: screens.HomepageScreen,
  },
  {
    path: '/analyzer',
    name: RouterName.Analyzer,
    component: LayoutExplorer,
    props: {
      translate: 'titles.analyzer',
    },
    children: [
      {
        path: '/graphs',
        name: RouterName.Graphs,
        component: screens.GraphsListScreen,
        props: {
          layout: LayoutExplorerGraphs,
          translate: 'titles.graphs',
        },
      },
      {
        path: '/:network',
        component: LayoutExplorer,
        props: {
          translate: 'titles.analyzer',
        },
        children: [
          {
            path: '/visualization',
            children: [
              {
                path: '/:uuid',
                name: RouterName.ExplorerSavedVisualization,
                component: screens.NewVisualizationScreen,
                props: { ...defaultProps, layout: LayoutGraph },
              },
              {
                path: '/address/:address',
                name: RouterName.ExplorerAddressVisualization,
                component: screens.NewVisualizationScreen,
                props: { ...defaultProps, layout: LayoutGraph },
              },
              {
                path: '/transaction/:txid',
                name: RouterName.ExplorerTransactionVisualization,
                component: screens.NewVisualizationScreen,
                props: { ...defaultProps, layout: LayoutGraph },
              },
            ],
          },
          {
            path: '/block_transactions/:hash',
            name: RouterName.ExplorerBlockTransactions,
            component: screens.ExplorerBlockTransactionsScreen,
            props: {
              ...authProps,
              layout: LayoutExplorerBlockTransaction,
            },
          },
          {
            path: '/block/:height',
            name: RouterName.ExplorerBlock,
            component: screens.ExplorerBlockScreen,
            props: {
              ...authProps,
              layout: LayoutExplorerBlock,
              translate: 'titles.block',
            },
          },
          {
            path: '/transaction/:txid',
            name: RouterName.ExplorerTransactionDetails,
            component: screens.ExplorerTransactionDetailsScreen,
            props: {
              ...authProps,
              layout: LayoutExplorerTransaction,
              translate: 'links.transaction',
            },
          },
          {
            path: '/wallet/:wid',
            name: RouterName.ExplorerWallet,
            component: screens.ExplorerWalletScreen,
            props: {
              ...authProps,
              layout: LayoutExplorerWallet,
              translate: 'titles.wallet',
            },
          },
          {
            path: '/address/:address',
            name: RouterName.ExplorerAddressDetails,
            component: screens.ExplorerAddressScreen,
            props: {
              ...authProps,
              layout: LayoutExplorerAddress,
              translate: 'titles.analyzer',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/info',
    children: [
      {
        path: '/categories',
        name: RouterName.CategoryListAll,
        component: screens.CategoryAllListScreen,
        props: {
          ...defaultProps,
          auth: false,
          translate: 'titles.allCategories',
        },
      },
    ],
  },
  {
    path: '/profile',
    name: RouterName.Profile,
    component: screens.ProfileScreen,
    props: {
      ...authProps,
      layout: LayoutProfile,
      translate: 'titles.profile',
    },
  },
  {
    path: '/user',
    children: [
      {
        path: '/list',
        name: RouterName.UserList,
        component: screens.UserListScreen,
        props: {
          ...authProps,
          layout: LayoutUserCreate,
          translate: 'titles.userList',
        },
      },
      {
        path: '/create',
        name: RouterName.UserCreate,
        component: screens.UserCreateScreen,
        props: {
          ...authProps,
          layout: LayoutUserCreate,
          translate: 'titles.createUser',
          hasBack: true,
        },
      },
    ],
  },
  {
    path: '/auth',
    children: [
      {
        path: '/login',
        name: RouterName.AuthLogin,
        component: screens.AuthLoginScreen,
        props: cleanLoginProps,
      },
      {
        path: '/registration',
        name: RouterName.AuthRegistration,
        component: screens.AuthRegistrationScreen,
        props: cleanLoginProps,
      },
      {
        path: '/registration/confirm/:token',
        name: RouterName.AuthRegistrationConfirm,
        component: screens.AuthRegistrationConfirmScreen,
        props: cleanLoginProps,
      },
      {
        path: '/restore',
        name: RouterName.AuthRestore,
        component: screens.AuthRestoreScreen,
        props: cleanLoginProps,
      },
      {
        path: '/restore/check/:token',
        name: RouterName.AuthRestoreConfirm,
        component: screens.AuthRestoreConfirmScreen,
        props: cleanLoginProps,
      },
    ],
  },
  {
    path: '/dashboard',
    name: RouterName.Transactions,
    component: screens.TransactionsScreen,
    props: {
      ...authProps,
      layout: LayoutTransactions,
      translate: 'titles.api',
    },
    children: [
      {
        path: '/:type',
        name: RouterName.TransactionsType,
        component: screens.TransactionsScreen,
        props: {
          ...authProps,
          layout: LayoutTransactions,
          translate: 'titles.api',
        },
      },
    ],
  },
  {
    path: '/tariffs',
    children: [
      {
        path: '/analyzer',
        name: RouterName.TariffAnalyzer,
        component: screens.TariffAnalyzer,
        props: {
          ...authProps,
          layout: LayoutTariff,
        },
      },
      {
        path: '/markup',
        name: RouterName.TariffMarkup,
        component: screens.TariffApi,
        props: {
          ...authProps,
          layout: LayoutTariff,
        },
      },
      {
        path: '/api',
        name: RouterName.TariffApi,
        component: screens.TariffApi,
        props: {
          ...authProps,
          layout: LayoutTariff,
        },
      },
    ],
  },
  {
    path: '/downloadPdf/:network/:address',
    name: RouterName.DownloadPDF,
    component: screens.DownloadPDF,
  },
  {
    path: '/technical-works',
    name: RouterName.TechnicalWorks,
    component: screens.TechnicalWorks,
  },
  {
    path: '(.*)',
    name: RouterName.NotFound,
    component: screens.PageNotFound,
  },
];

import React, { useState } from 'react';
import { Router } from 'react-justanother-router';
import { history, router, renderer } from './router';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './apollo';
import { AppContext } from './contexts';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import i18next from 'i18next';
import { RemoveApiKeyModal } from '@component/Profile';
import { ModalProviderProps, GoblinProvider, Toaster } from '@rubin-dev/goblin';
import { GraphRemoveModal, GraphRenameModal } from '@component/NewGraph';
import { LANGS, LangType } from '@shared/libs';
import { languageInstall } from './i18n/i18n';
import { ExplorerTransactionDriver } from '@component/explorer';
import { ModalMarkupTariffs } from '@component/explorer/ui/ExplorerAddressMentions/ModalTariffs';
import { ViewDetailsModal } from '@component/explorer/ui/ExplorerAddressMentions/ViewsDetailsModal';
import { DialogTariffSelect } from '@shared/ui/DialogTariffSelect';
import { RemoveUserDialog } from './screen/User/RemoveUserDialog';
import { EditUserDialog } from './screen/User/EditUserDialog';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
const componentDictionary: ModalProviderProps['components'] = {
  removeApiKey: RemoveApiKeyModal,
  renameGraph: GraphRenameModal,
  removeGraph: GraphRemoveModal,
  explorerTransaction: ExplorerTransactionDriver,
  modalMarkupTariffs: ModalMarkupTariffs,
  modalViewDetails: ViewDetailsModal,
  dialogTariffSelect: DialogTariffSelect,
  removeUserDialog: RemoveUserDialog,
  editUserDialog: EditUserDialog,
};
export const Root = (): JSX.Element => {
  const [lang, setLang] = useState<LangType>(languageInstall());
  const changeLang = (language: LangType) => {
    setLang(language);
    i18next.changeLanguage(language);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <AppContext.Provider
          value={{
            languages: LANGS,
            language: lang,
            setLang: changeLang,
          }}
        >
          <GoblinProvider modalComponents={componentDictionary}>
            <Router history={history} router={router} renderer={renderer}>
              <Toaster />
            </Router>
          </GoblinProvider>
        </AppContext.Provider>
      </ApolloProvider>
    </QueryClientProvider>
  );
};

import React, { FC } from 'react';
import {
  Divider,
  LoaderOverlay,
  Spacer,
  TabItem,
  Tabs,
  Typography,
} from '@rubin-dev/goblin';
import { getNetworkEnum } from '@helpers/address';
import { useTranslation } from 'react-i18next';
import { AppError, ExplorerBlockTransactions } from '../../component';
import { useExplorerFindTransactionsWithBlockQuery } from '@apolloGenerated';
import { ListItem } from '@shared/ui';
import { getFormatDate } from '@utils/timestamp';

type ExplorerBlockScreenProps = {
  params: {
    height: string;
    network: string;
  };
  query: {
    page?: string;
  };
};

export const ExplorerBlockScreen: FC<ExplorerBlockScreenProps> = ({
  params,
  query,
}): JSX.Element => {
  const { t } = useTranslation();
  const network = getNetworkEnum(params.network);

  const { data, loading, error } = useExplorerFindTransactionsWithBlockQuery({
    variables: {
      network,
      height: parseInt(params.height, 10),
      page: 1,
      pageSize: 10,
    },
  });

  if (error) return <AppError error={t('errors.reloading')} />;
  if (loading) return <LoaderOverlay show={loading} fixed />;
  return (
    <>
      <ListItem
        label={`${t('titles.blockchain')}:`}
        value={params.network.toUpperCase()}
        row
      />
      <ListItem
        label={`${t('titles.time')}:`}
        value={getFormatDate(data?.explorerFindBlockByHeight.node?.timestamp)}
        row
      />
      <ListItem
        label={`${t('titles.height')}:`}
        value={String(data?.explorerFindBlockByHeight.node?.height) || '-'}
        row
      />
      <ListItem
        label={`${t('titles.hash')}:`}
        value={data?.explorerFindBlockByHeight.node?.hash || '-'}
        row
      />
      <Spacer size={16} />
      <Typography variant="head-20" color="on-surface-primary-1">
        {t('strings.transactions')}
      </Typography>
      <Spacer size={8} />
      <Divider />
      <Spacer size={8} />
      <ExplorerBlockTransactions
        height={params.height}
        network={network}
        initPage={query.page}
      />
    </>
  );
};

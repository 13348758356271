import { FC, useEffect, useMemo } from 'react';
import * as gqlTypes from '../../apolloGenerated';
import { bem } from 'classnames-bem';
import { AppError } from '../AppError';
import { useTranslation } from 'react-i18next';
import { useExplorerClusterForAddressQuery } from '@apolloGenerated';
import { RouterName, router } from '../../router';
import { Loader } from '@rubin-dev/goblin';
import cx from 'classnames';

type Props = {
  address: string;
  network: gqlTypes.Network;
  secondary?: boolean;
  hideTitle?: boolean;
  hideLink?: boolean;
  target?: string;
  setLink?: (link: string) => void;
};

export const ExplorerAddressWallet: FC<Props> = ({
  address,
  network,
  secondary = false,
  hideTitle = false,
  hideLink = false,
  target = '_self',
  setLink,
}) => {
  const { t } = useTranslation();
  const { loading, error, data } = useExplorerClusterForAddressQuery({
    variables: { network, address },
  });
  const cluster = useMemo(() => data?.explorerClusterForAddress?.cluster, [data]);

  useEffect(() => {
    if (setLink && cluster?.id && network) {
      setLink(router.urlFor(RouterName.ExplorerWallet, { wid: cluster?.id, network }));
    }
  }, [cluster?.id, network]);

  if (error) {
    return <AppError error={t('errors.reloading')} />;
  }

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const text = cluster?.owner ? cluster?.owner : '-';

  return (
    <div className={bem('b-app__wrapper', 'wrap')}>
      {!hideTitle && (
        <span className={bem('b-text__title', secondary ? 'secondary' : 'primary')}>
          Cluster &nbsp;
        </span>
      )}
      {hideLink || !cluster?.id ? (
        <span>{text}</span>
      ) : (
        <a
          className={cx('b-app__link', 'b-app__link_primary')}
          href={router.urlFor(RouterName.ExplorerWallet, {
            wid: cluster?.id,
            network: network,
          })}
          target={target}
        >
          {text}
        </a>
      )}
    </div>
  );
};

import React, { FC } from 'react';
import styles from './styles.module.scss';
import {
  ArrowLeftToRightIcon,
  ArrowRightToLeftIcon,
  CardItem,
  NetworkIcon,
} from '@shared/ui';
import { LinkService } from '@graph/models';
import { getNetworkEnum } from '@helpers/address';
import { CopyHash, Typography } from '@rubin-dev/goblin';
import { openAddress, openWallet } from '@shared/libs';
import { useExplorerClusterQuery } from '@apolloGenerated';

export type LineInfoProps = {
  hash: string;
  network: string;
};
export const LineInfo: FC<LineInfoProps> = ({ hash, network }) => {
  const networkEnum = getNetworkEnum(network);
  const { target, source } = LinkService.getAddressesByHash(hash || ',');
  const { data: sourceCluster } = useExplorerClusterQuery({
    variables: { network: networkEnum, id: source },
  });
  const walletSource = sourceCluster?.explorerCluster?.cluster;
  const { data: targetCluster } = useExplorerClusterQuery({
    variables: { network: networkEnum, id: target },
  });
  const walletTarget = targetCluster?.explorerCluster?.cluster;

  return (
    <CardItem py={8} px={8} className={styles.lineInfo}>
      <div className={styles.lineInfo__content}>
        <div className={styles.lineInfo__topLine}>
          <ArrowLeftToRightIcon color="var(--error-2)" width={500} />
          {/*<Typography*/}
          {/*  className={styles.lineInfo__sentAmount}*/}
          {/*  variant="head-14"*/}
          {/*  color="error-2"*/}
          {/*>*/}
          {/*  -127,351.05702*/}
          {/*</Typography>*/}
        </div>
        <div className={styles.lineInfo__center}>
          <div className={styles.lineInfo__source}>
            <CopyHash
              hash={source}
              shorted
              onClickHash={() => openAddress(networkEnum, source)}
            />
            {walletSource?.id && (
              <CopyHash
                hash={walletSource.id}
                label={walletSource.owner || 'Entity'}
                onClickHash={() => openWallet(networkEnum, walletSource.id)}
              />
            )}
          </div>
          {/*<div className={styles.lineInfo__dateFirst}>*/}
          {/*  <Typography variant="body-12" color="on-surface-primary-2">*/}
          {/*    First transaction*/}
          {/*  </Typography>*/}
          {/*  <Typography variant="body-12" color="on-surface-primary-1">*/}
          {/*    Feb 01, 2023 ,03:25:47 pm*/}
          {/*  </Typography>*/}
          {/*</div>*/}
          <div className={styles.lineInfo__token}>
            <NetworkIcon size={32} network={networkEnum} />
            <Typography variant="body-12" color="on-surface-primary-1">
              {network}
            </Typography>
          </div>
          {/*<div className={styles.lineInfo__dateLast}>*/}
          {/*  <Typography variant="body-12" color="on-surface-primary-2">*/}
          {/*    Last transaction*/}
          {/*  </Typography>*/}
          {/*  <Typography variant="body-12" color="on-surface-primary-1">*/}
          {/*    Feb 13, 2023, 03:25:47 pm*/}
          {/*  </Typography>*/}
          {/*</div>*/}
          <div className={styles.lineInfo__target}>
            <CopyHash
              hash={target}
              shorted
              onClickHash={() => openAddress(networkEnum, target)}
            />
            {walletTarget?.id && (
              <CopyHash
                hash={walletTarget.id}
                label={walletTarget.owner || 'Entity'}
                onClickHash={() => openWallet(networkEnum, walletTarget.id)}
              />
            )}
          </div>
        </div>
        <div className={styles.lineInfo__btmLine}>
          <ArrowRightToLeftIcon color="var(--success-2)" width={500} />
          {/*<Typography*/}
          {/*  className={styles.lineInfo__receiveAmount}*/}
          {/*  variant="head-14"*/}
          {/*  color="success-2"*/}
          {/*>*/}
          {/*  +9,131.09761*/}
          {/*</Typography>*/}
        </div>
      </div>
    </CardItem>
  );
};

import { UseGraphDataController } from './types';
import { getNetworkEnum } from '@helpers/address';
import { useCallback, useEffect, useMemo } from 'react';
import { TableType } from '@graph/types/table';
import {
  ChangeCheckedEventParams,
  ClickNodeEventParams,
  EventName,
  useGraphStatusStore,
  useGraphStore,
} from '../../store';
import { isLinkItem, isNodeItem } from '../../helpers/table';
import {
  useGraphIntegration,
  useGraphLineController,
  useGraphLineData,
  useGraphNodeData,
} from '@graph/libs/hooks';
import { AddressTable, LinkList, LinkService, Meta, NodeList } from '@graph/models';
import { useGraphData } from '../useGraphData';
import { detectInitHash, detectInitType } from '../../helpers/initHashes';
import getReverseTxType from '../../helpers/getReverseTxType';
import { AddressCheckedType } from '@apolloGenerated';

export const useGraphDataController: UseGraphDataController = ({
  network,
  txid,
  address,
  uuid,
}) => {
  // STORE
  const { event, sendEvent } = useGraphStore();
  const { isInitialized } = useGraphStatusStore();

  // DATA
  const initType = detectInitType({ uuid, txid, address });
  const initHash = detectInitHash({ uuid, txid, address }, getNetworkEnum(network));
  const [
    { onUpdateData, onUpdateNodes, onUpdateHash, onUpdateMeta },
    {
      hash: currentHash,
      type: currentType,
      prevHash,
      prevType,
      tableData,
      nodes,
      links,
      canvas,
    },
  ] = useGraphData({ initHash, initType });

  // DATA HOOKS
  const { handleNodeData } = useGraphNodeData();
  const {
    handleBetweenAddressData,
    initBetweenAddressByTr,
    addAllTransactionsWithCurrentAddress,
  } = useGraphLineData();
  const [{ initDataByUuid }] = useGraphIntegration();

  // LINE HOOKS
  const { updateLine } = useGraphLineController();

  // NETWORK
  const networkEnum = useMemo(() => getNetworkEnum(network), [network]);
  useEffect(() => {
    Meta.network = networkEnum;
    Meta.graphName = address! || txid!;
  }, []);

  // EVENTS
  useEffect(() => {
    switch (event?.type) {
      case EventName.TABLE_CHANGE_CHECKED:
        handleChangeChecked(event.params!);
        break;
      case EventName.GRAPH_CLICK:
        handleClickGraphNode(event.params!.item);
        break;
      case EventName.GRAPH_NODE_REMOVE:
        handleRemoveGraphNode(event.params!.hash);
        break;
      case EventName.DATA_UPDATE_HASH:
        onUpdateHash({ hash: event.params!.hash, type: event.params!.type });
        break;
      case EventName.DATA_ADD_ADDRESS_WITH_CONNECTIONS:
        addAddressWithFullConnections(event.params!.address);
        break;
      case EventName.DATA_UPDATE:
        onUpdateData();
        break;
      case EventName.DATA_SAVE_POSITION:
        onUpdateMeta(event.params!);
        break;
      case EventName.DATA_INIT_HASH:
        initGraph(event.params!.hash, event.params!.type);
        break;
      case EventName.DATA_SAVE_CANVAS:
        onUpdateNodes(event.params!);
        break;
    }
  }, [event]);

  useEffect(() => {
    if (!isInitialized) initGraph(currentHash, currentType);
    else updateGraph(currentHash, currentType);
  }, [currentHash, currentType]);

  const initGraph = useCallback(
    (hash: string, type: TableType) => {
      switch (type) {
        case TableType.Cluster:
        case TableType.Address:
          handleNodeData(hash, networkEnum);
          break;
        case TableType.Transaction:
          initBetweenAddressByTr(hash, networkEnum);
          break;
        case TableType.UUID:
          initDataByUuid(hash);
          break;
      }
    },
    [networkEnum, handleNodeData, initBetweenAddressByTr, initDataByUuid],
  );

  const updateGraph = useCallback(
    (hash: string, type: TableType) => {
      if (!hash) return;
      switch (type) {
        case TableType.Cluster:
        case TableType.Address:
          handleNodeData(hash, networkEnum);
          break;
        case TableType.Transaction:
          handleBetweenAddressData(hash);
          break;
      }
    },
    [networkEnum, handleNodeData, handleBetweenAddressData],
  );
  const handleChangeChecked = useCallback(
    async ({ item, value }: ChangeCheckedEventParams): Promise<void> => {
      // Запрашиваем инфу по адресу
      await handleNodeData(item.targetAddress!, networkEnum);
      // Обновляем лайн
      updateLine(value, item);
    },
    [handleNodeData, updateLine, networkEnum],
  );

  const handleClickGraphNode = useCallback(
    (item: ClickNodeEventParams['item']) => {
      if (isNodeItem(item))
        onUpdateHash({
          hash: item.uuid,
          type: NodeList.getTableDataByHash(item.uuid).mode,
        });
      else if (isLinkItem(item))
        onUpdateHash({
          hash: LinkService.generateLinkHash(item.source.uuid, item.target.uuid),
          type: TableType.Transaction,
        });
    },
    [onUpdateHash],
  );

  const handleRemoveGraphNode = useCallback(
    (hash: string) => {
      const removedNode = NodeList.getTableDataByHash(hash);
      // Удаляем адрес из списка
      NodeList.remove(hash);

      // Получаем все связи удаляемой ноды
      LinkService.getLinksByHash(hash).forEach((el) => {
        const curHash = hash === el.source ? el.target : el.source;
        // Устанавливаем false во всех элементах таблицы из которых удалили адрес
        el.amount.forEach((amountItem) => {
          let removeAmountHash: AddressCheckedType = AddressTable.getCheckedItem(
            amountItem.txid,
            amountItem.type,
            curHash,
            hash,
          );
          if (
            removedNode.hasChecked(
              AddressTable.getCheckedItem(
                amountItem.txid,
                amountItem.type,
                hash,
                curHash,
              ),
            )
          )
            removeAmountHash = {
              ...removeAmountHash,
              type: getReverseTxType(amountItem.type),
            };
          NodeList.getTableDataByHash(curHash).removeChecked(removeAmountHash);
        });
        // Удаляем связь
        LinkList.removeLink(el.hash);
      });

      // Если удалили текущую открытую ноду:
      if (currentHash === hash) {
        // Если есть предыдущий хеш и он не равен текущей ноде, то открываем прошлую активную ноду
        if (prevHash !== currentHash && NodeList.getTableDataByHash(prevHash))
          onUpdateHash({ hash: prevHash, type: prevType });
        // В ином случае просто закрываем окно очисткой текущей активной ноды
        else onUpdateHash({ hash: '', type: TableType.Address });
      }
      // Обновляем данные в графе
      sendEvent({ type: EventName.DATA_UPDATE }, true);
    },
    [sendEvent, currentHash, prevHash, prevType, onUpdateHash],
  );

  const addAddressWithFullConnections = (hash: string) => {
    const addresses = Object.keys(NodeList.hashList);
    const hasInGraph = addresses.includes(hash);
    onUpdateHash({ hash, type: TableType.Address });
    if (addresses.length && !hasInGraph)
      addAllTransactionsWithCurrentAddress(hash, addresses, networkEnum);
  };

  return {
    hash: currentHash,
    type: currentType,
    tableData,
    nodes,
    links,
    canvas,
  };
};

import { TechnicalWorks } from '../screen';

export enum RouterName {
  HomePage = 'homepage',
  Markup = 'markup',
  MarkupDetails = 'details',
  Analyzer = 'analyzer',
  Graphs = 'graphs',
  ExplorerSavedVisualization = 'explorer_saved_visualization',
  ExplorerAddressVisualization = 'explorer_address_visualization',
  ExplorerTransactionVisualization = 'explorer_tr_visualization',
  ExplorerBlock = 'explorer_block',
  ExplorerBlockTransactions = 'explorer_block_transactions',
  ExplorerAddressDetails = 'explorer_address_details',
  ExplorerTransactionDetails = 'explorer_transaction_details',
  ExplorerWallet = 'explorer_wallet',
  CategoryListAll = 'category_list_all',
  Profile = 'profile',
  UserList = 'user_list',
  UserCreate = 'user_create',
  AuthLogin = 'auth_login',
  AuthRegistration = 'auth_registration',
  AuthRegistrationConfirm = 'auth_registration_confirm',
  AuthRestore = 'auth_restore',
  AuthRestoreConfirm = 'auth_restore_confirm',
  Transactions = 'transactions',
  TransactionsType = 'transactions_type',
  TariffAnalyzer = 'tariff_analyzer',
  TariffMarkup = 'tariff_markup',
  TariffApi = 'tariff_api',
  DownloadPDF = 'download_pdf',
  NotFound = '404',
  TechnicalWorks = 'technical_works',
}

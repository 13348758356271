import React, { FC, useRef, useState } from 'react';
import cx from 'classnames';
import { EventName, useGraphStore } from '@graph/libs';
import { Fade, Typography, CloseIcon } from '@rubin-dev/goblin';
import { Maybe, Network } from '@apolloGenerated';
import { useTranslation } from 'react-i18next';
import { amount, format } from '@aml/amount';
import styles from './styles.module.scss';
import { RiskDot } from '@component/NewRisk/ui/RiskDot/RiskDot';
import { ListItem } from '@shared/ui';

export type NodeLabelProps = {
  risk: Maybe<number>;
  label: string;
  hash: string;
  balance: number;
  total: number;
  owner?: Maybe<string>;
  category?: string;
  active?: boolean;
  removed?: boolean;
  network: Network;
};

export const NodeLabel: FC<NodeLabelProps> = ({
  hash,
  risk,
  label,
  active,
  balance,
  owner,
  category,
  total,
  removed,
  network,
}): JSX.Element => {
  const timer = useRef<Maybe<NodeJS.Timeout>>(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { sendEvent } = useGraphStore();
  const handleClickRemove = () => {
    sendEvent({ type: EventName.GRAPH_NODE_REMOVE, params: { hash } });
  };

  const handleMouseEnter = () => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => setIsOpen(true), 1500);
  };
  const handleMouseLeave = () => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => setIsOpen(false), 500);
  };

  return (
    <div
      className={cx(
        styles.nodeLabel,
        active && styles.nodeLabel_active,
        removed && styles.nodeLabel_removed,
      )}
      key={hash}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.nodeLabel__main}>
        {risk !== null && <RiskDot risk={risk} size={6} />}
        <Typography variant="body-14" color="on-secondary-2">
          {owner || label}
        </Typography>
        {removed && (
          <div
            className={styles.nodeLabel__remove}
            onClick={handleClickRemove}
            role="button"
            tabIndex={0}
          >
            <CloseIcon width={16} height={16} color="var(--error-2)" />
          </div>
        )}
      </div>
      {category && (
        <Typography variant="body-14" color={active ? 'error-2' : 'on-secondary-2'}>
          Reported: {category}
        </Typography>
      )}
      <Fade isOpen={isOpen}>
        <div className={styles.nodeLabel__popover}>
          <ListItem
            className={styles.nodeLabel__popoverItem}
            label={`${t('strings.transactions')}:`}
            value={
              <Typography variant="body-16" color="on-surface-primary-1">
                {balance ? amount(balance, network, true) : '0'}
              </Typography>
            }
            row
          />
          <ListItem
            className={styles.nodeLabel__popoverItem}
            label={`${t('strings.balance')}:`}
            value={
              <Typography variant="body-16" color="on-surface-primary-1">
                {format(total, 0)}
              </Typography>
            }
            row
          />
        </div>
      </Fade>
    </div>
  );
};

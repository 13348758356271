import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  useQuery,
  useMutation,
  useQueryClient,
  type QueryClient,
  type UseMutationOptions,
  type UseQueryOptions,
  type MutationFunction,
  type UseMutationResult,
  type UseQueryResult,
} from '@tanstack/react-query';

export type CalculateItem = {
  number?: number;
  total?: string;
  total_human?: string;
  percent?: number;
  percent_raw?: number;
  risk?: number;
  risk_raw?: number;
  acceptable?: number;
  name?: string;
  color?: string;
  i18n?: I18n;
};
export type CalculatedRisk = {
  total?: string;
  total_human?: string;
  risk?: number;
  risk_raw?: number;
  items?: {
    [key: string]: CalculateItem;
  };
};
export type Cluster = {
  cluster?: string;
  owner?: string;
  category_name?: string;
  category_number?: number;
  risk?: number;
};
export type Dict = {
  version?: number;
  params?: {
    [key: string]: DictDictItem;
  };
};
export type DictItemDictDescription = {
  ru?: string;
  en?: string;
};
export type DictDictItem = {
  name?: string;
  risk?: number;
  number?: number;
  skip?: boolean;
  risk_threshold?: number;
  rp_rate?: number;
  i18n?: DictItemDictDescription;
  color?: string;
};
export type GoogleProtobufAny = {
  [key: string]: any;
};
export type I18n = {
  ru?: string;
  en?: string;
};
export type Mention = {
  id?: string;
  link?: string;
  mention_label?: string;
  owner?: string;
  created_at?: string;
  category_number?: number;
  address?: string;
  domain?: string;
  has_screenshots?: boolean;
  description?: string;
  name?: string;
  other_addresses?: {
    [key: string]: string;
  };
  category_name?: string;
  risk_color?: string;
  mention_source?: MentionSource;
  report_id?: string;
};
export type MentionListResponse = {
  edge?: Mention[];
};
export type MentionSource = {
  id?: string;
  name?: string;
  description?: string;
  level?: number;
  source_address?: string;
  category_number?: number;
  version?: number;
};
export type ReportedRisk = {
  category_name?: string;
  category_number?: number;
  risk?: number;
};
export type RiskResponse = {
  risk?: CalculatedRisk;
  dict?: Dict;
  total?: string;
  total_human?: string;
};
export type Status = {
  code?: number;
  message?: string;
  details?: GoogleProtobufAny[];
};
export type SummaryAddressRiskResponse = {
  risk?: number;
  calculated?: CalculatedRisk;
  reported?: ReportedRisk;
  cluster?: Cluster;
  category?: string[];
  dict?: Dict;
};
export type VersionsResponse = {
  versions?: number[];
};
export type AxiosConfig = {
  paramsSerializer?: AxiosRequestConfig['paramsSerializer'];
};
export type Config = {
  mutations?: MutationConfigs;
  axios?: AxiosConfig;
};

export function initialize(axios: AxiosInstance, config?: Config) {
  const requests = makeRequests(axios, config?.axios);
  return {
    requests,
    queries: makeQueries(requests),
    mutations: makeMutations(requests, config?.mutations),
  };
}

function useRapiniMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  config?: (
    queryClient: QueryClient,
  ) => Pick<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'onSuccess' | 'onSettled' | 'onError'
  >,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { onSuccess, onError, onSettled, ...rest } = options ?? {};
  const queryClient = useQueryClient();
  const conf = config?.(queryClient);
  const mutationOptions: typeof options = {
    onSuccess: (data: TData, variables: TVariables, context?: TContext) => {
      conf?.onSuccess?.(data, variables, context!);
      onSuccess?.(data, variables, context!);
    },
    onError: (error: TError, variables: TVariables, context?: TContext) => {
      conf?.onError?.(error, variables, context);
      onError?.(error, variables, context);
    },
    onSettled: (
      data: TData | undefined,
      error: TError | null,
      variables: TVariables,
      context?: TContext,
    ) => {
      conf?.onSettled?.(data, error, variables, context);
      onSettled?.(data, error, variables, context);
    },
    ...rest,
  };
  return useMutation({ mutationFn, ...mutationOptions });
}

function nullIfUndefined<T>(value: T): NonNullable<T> | null {
  return typeof value === 'undefined' ? null : (value as NonNullable<T> | null);
}

export const queryKeys = {
  riskServiceAddressRisk: (id: string, version?: number) =>
    ['riskServiceAddressRisk', id, nullIfUndefined(version)] as const,
  riskServiceAddressVersions: (id: string) => ['riskServiceAddressVersions', id] as const,
  riskDictServiceCurrentDict: () => ['riskDictServiceCurrentDict'] as const,
  mentionServiceMentionList: (address: string) =>
    ['mentionServiceMentionList', address] as const,
  riskServiceTransactionRisk: (id: string, version?: number) =>
    ['riskServiceTransactionRisk', id, nullIfUndefined(version)] as const,
  riskServiceTransactionVersions: (id: string) =>
    ['riskServiceTransactionVersions', id] as const,
  riskServiceSummaryAddressRisk: (id: string, version?: number) =>
    ['riskServiceSummaryAddressRisk', id, nullIfUndefined(version)] as const,
  riskDictServiceVersionDict: (version: number) =>
    ['riskDictServiceVersionDict', version] as const,
} as const;
export type QueryKeys = typeof queryKeys;

function makeRequests(axios: AxiosInstance, config?: AxiosConfig) {
  return {
    riskServiceAddressRisk: (id: string, version?: number) =>
      axios
        .request<RiskResponse>({
          method: 'get',
          url: `/address/${id}`,
          params: {
            ...(version !== undefined ? { version } : undefined),
          },
          paramsSerializer: config?.paramsSerializer,
        })
        .then((res) => res.data),
    riskServiceAddressVersions: (id: string) =>
      axios
        .request<VersionsResponse>({
          method: 'get',
          url: `/address/${id}/versions`,
        })
        .then((res) => res.data),
    riskDictServiceCurrentDict: () =>
      axios
        .request<Dict>({
          method: 'get',
          url: `/current`,
        })
        .then((res) => res.data),
    mentionServiceMentionList: (address: string) =>
      axios
        .request<MentionListResponse>({
          method: 'get',
          url: `/mention/${address}`,
        })
        .then((res) => res.data),
    riskServiceTransactionRisk: (id: string, version?: number) =>
      axios
        .request<RiskResponse>({
          method: 'get',
          url: `/transaction/${id}`,
          params: {
            ...(version !== undefined ? { version } : undefined),
          },
          paramsSerializer: config?.paramsSerializer,
        })
        .then((res) => res.data),
    riskServiceTransactionVersions: (id: string) =>
      axios
        .request<VersionsResponse>({
          method: 'get',
          url: `/transaction/${id}/versions`,
        })
        .then((res) => res.data),
    riskServiceSummaryAddressRisk: (id: string, version?: number) =>
      axios
        .request<SummaryAddressRiskResponse>({
          method: 'get',
          url: `/v2/address/${id}`,
          params: {
            ...(version !== undefined ? { version } : undefined),
          },
          paramsSerializer: config?.paramsSerializer,
        })
        .then((res) => res.data),
    riskDictServiceVersionDict: (version: number) =>
      axios
        .request<Dict>({
          method: 'get',
          url: `/version/${version}`,
        })
        .then((res) => res.data),
  } as const;
}

export type Requests = ReturnType<typeof makeRequests>;
export type Response<T extends keyof Requests> = Awaited<ReturnType<Requests[T]>>;

function makeQueries(requests: Requests) {
  return {
    useRiskServiceAddressRisk: (
      id: string,
      version?: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceAddressRisk'>,
          unknown,
          Response<'riskServiceAddressRisk'>,
          ReturnType<QueryKeys['riskServiceAddressRisk']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceAddressRisk'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceAddressRisk(id, version),
        queryFn: () => requests.riskServiceAddressRisk(id, version),
        ...options,
      }),
    useRiskServiceAddressVersions: (
      id: string,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceAddressVersions'>,
          unknown,
          Response<'riskServiceAddressVersions'>,
          ReturnType<QueryKeys['riskServiceAddressVersions']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceAddressVersions'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceAddressVersions(id),
        queryFn: () => requests.riskServiceAddressVersions(id),
        ...options,
      }),
    useRiskDictServiceCurrentDict: (
      options?: Omit<
        UseQueryOptions<
          Response<'riskDictServiceCurrentDict'>,
          unknown,
          Response<'riskDictServiceCurrentDict'>,
          ReturnType<QueryKeys['riskDictServiceCurrentDict']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskDictServiceCurrentDict'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskDictServiceCurrentDict(),
        queryFn: () => requests.riskDictServiceCurrentDict(),
        ...options,
      }),
    useMentionServiceMentionList: (
      address: string,
      options?: Omit<
        UseQueryOptions<
          Response<'mentionServiceMentionList'>,
          unknown,
          Response<'mentionServiceMentionList'>,
          ReturnType<QueryKeys['mentionServiceMentionList']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'mentionServiceMentionList'>, unknown> =>
      useQuery({
        queryKey: queryKeys.mentionServiceMentionList(address),
        queryFn: () => requests.mentionServiceMentionList(address),
        ...options,
      }),
    useRiskServiceTransactionRisk: (
      id: string,
      version?: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceTransactionRisk'>,
          unknown,
          Response<'riskServiceTransactionRisk'>,
          ReturnType<QueryKeys['riskServiceTransactionRisk']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceTransactionRisk'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceTransactionRisk(id, version),
        queryFn: () => requests.riskServiceTransactionRisk(id, version),
        ...options,
      }),
    useRiskServiceTransactionVersions: (
      id: string,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceTransactionVersions'>,
          unknown,
          Response<'riskServiceTransactionVersions'>,
          ReturnType<QueryKeys['riskServiceTransactionVersions']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceTransactionVersions'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceTransactionVersions(id),
        queryFn: () => requests.riskServiceTransactionVersions(id),
        ...options,
      }),
    useRiskServiceSummaryAddressRisk: (
      id: string,
      version?: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskServiceSummaryAddressRisk'>,
          unknown,
          Response<'riskServiceSummaryAddressRisk'>,
          ReturnType<QueryKeys['riskServiceSummaryAddressRisk']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskServiceSummaryAddressRisk'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskServiceSummaryAddressRisk(id, version),
        queryFn: () => requests.riskServiceSummaryAddressRisk(id, version),
        ...options,
      }),
    useRiskDictServiceVersionDict: (
      version: number,
      options?: Omit<
        UseQueryOptions<
          Response<'riskDictServiceVersionDict'>,
          unknown,
          Response<'riskDictServiceVersionDict'>,
          ReturnType<QueryKeys['riskDictServiceVersionDict']>
        >,
        'queryKey' | 'queryFn'
      >,
    ): UseQueryResult<Response<'riskDictServiceVersionDict'>, unknown> =>
      useQuery({
        queryKey: queryKeys.riskDictServiceVersionDict(version),
        queryFn: () => requests.riskDictServiceVersionDict(version),
        ...options,
      }),
  } as const;
}

type MutationConfigs = {};

function makeMutations(requests: Requests, config?: Config['mutations']) {
  return {} as const;
}

import { AddressTable, Link, LinkList, NodeList } from '@graph/models';
import getReverseTxType from '../../helpers/getReverseTxType';
import { LinkCustomColor, Transaction } from '@graph/modules';
import { EventName, useGraphStore } from '@graph/libs';
import { useCallback, useEffect } from 'react';
import { Maybe } from '@apolloGenerated';

export const useGraphLineController = () => {
  const { event, sendEvent } = useGraphStore();

  useEffect(() => {
    switch (event?.type) {
      case EventName.MENU_CHANGE_LINK_COLOR: {
        const { source, target, color } = event.params!;
        handleChangeLinkColor(source, target, color);
        break;
      }
    }
  }, [event]);

  const handleChangeLinkColor = useCallback(
    (source: string, target: string, color: Maybe<LinkCustomColor>) => {
      const link = LinkList.getLinkByHashes(source, target);
      link.setCustomColor(color);
      sendEvent({
        type: EventName.DATA_UPDATE,
      });
    },
    [sendEvent],
  );

  const addLink = useCallback((item: Transaction) => {
    const targetHash = item.targetAddress!;
    const sourceHash = item.sourceAddress!;
    const linkItem = LinkList.getLinkByHashes(targetHash, sourceHash);
    if (linkItem) linkItem.sumLink(item);
    else {
      const linkItem = new Link(targetHash, sourceHash);
      LinkList.addLink(linkItem);
      linkItem.addAmount(item.txid, +item.amount, item.type);
    }
  }, []);

  const removeLink = useCallback((item: Transaction) => {
    const linkItem = LinkList.getLinkByHashes(item.sourceAddress!, item.targetAddress!);
    if (!linkItem) return;
    if (linkItem.checkCanDeleted()) LinkList.removeLink(linkItem.hash);
    else linkItem.minusLink(item);
  }, []);

  const updateLine = useCallback(
    (value: boolean, item: Transaction) => {
      const targetAddress = item.targetAddress!;
      const sourceAddress = item.sourceAddress!;
      const transactionSourceHash = AddressTable.getCheckedItem(
        item.txid,
        getReverseTxType(item.type),
        targetAddress,
        sourceAddress,
      );
      const transactionTargetHash = AddressTable.getCheckedItem(
        item.txid,
        item.type,
        sourceAddress,
        targetAddress,
      );
      // Добавляем или удаляем связь
      if (value) {
        addLink(item);
        // Устанавливаем чекбокс в текущей таблице
        NodeList.getTableDataByHash(targetAddress).addChecked(transactionSourceHash);
        // Устанавливаем чекбокс в таргет таблице
        NodeList.getTableDataByHash(sourceAddress).addChecked(transactionTargetHash);
        return;
      }
      removeLink(item);
      // Удаляем чекбокс в текущей таблице
      NodeList.getTableDataByHash(targetAddress).removeChecked(transactionSourceHash);
      // Удаляем чекбокс в таргет таблице
      NodeList.getTableDataByHash(sourceAddress).removeChecked(transactionTargetHash);
    },
    [addLink, removeLink],
  );

  return {
    updateLine,
    removeLink,
    addLink,
  };
};

import { FC } from 'react';
import styles from './styles.module.scss';
import { Dialog, Loader, Modal } from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';

export const GraphLoader: FC = () => {
  const { t } = useTranslation();
  return (
    <Modal
      className={styles.graphLoader}
      isOpen
      onClose={() => {}}
      overlayProps={{ background: 'transparent' }}
    >
      <Dialog title={`${t('titles.loading')}...`}>
        <div className={styles.graphLoader__loader}>
          <Loader size={64} width={4} />
        </div>
      </Dialog>
    </Modal>
  );
};

import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-justanother-router';
import { useTranslation } from 'react-i18next';
import { router } from '../../../../router';
import { RouterName } from '../../../../router';
import { CopyHash, LoaderOverlay, TableController, Typography } from '@rubin-dev/goblin';
import { Network, useExplorerFindTransactionsWithBlockQuery } from '@apolloGenerated';
import { openAddress, openTransaction } from '@shared/libs';

export type ExplorerBlockTransactionsTableProps = {
  initPage?: string;
  height: string;
  network: Network;
};

export const ExplorerBlockTransactions: FC<ExplorerBlockTransactionsTableProps> = ({
  height,
  network,
  initPage,
}) => {
  const { t } = useTranslation();
  const pageSize = 10;
  const [page, setPage] = useState(Number(initPage) || 1);
  const onPageChange = (page: number) => {
    setPage(page);
    history.pushState(
      '',
      'data.seo.title',
      router.urlFor(
        RouterName.ExplorerBlock,
        {
          height,
          network,
        },
        { page },
      ),
    );
  };

  const { data, previousData, loading } = useExplorerFindTransactionsWithBlockQuery({
    variables: {
      height: parseInt(height, 10),
      network,
      page,
      pageSize,
    },
  });

  const headers = [{ value: 'hash' }];
  const items = useMemo(
    () =>
      (
        data?.explorerFindTransactionsInBlock.edge ||
        previousData?.explorerFindTransactionsInBlock.edge ||
        []
      ).map((el) => ({
        hash: el?.props?.['txid'] ? (
          <CopyHash
            hash={el.props['txid']}
            onClickHash={() => openTransaction(network, el.props['txid'])}
          />
        ) : (
          '-'
        ),
      })),
    [data],
  );
  const total = useMemo(
    () =>
      data?.explorerFindTransactionsInBlock.total ||
      previousData?.explorerFindTransactionsInBlock.total ||
      0,
    [data],
  );

  if (!total) return <div>{t('strings.noresults')}</div>;
  return (
    <div className="relative">
      <TableController
        data={items}
        headers={headers}
        total={total}
        onChangePage={onPageChange}
        pageSize={pageSize}
        initPage={Number(page || 1)}
        notResetPage
      />
      <LoaderOverlay show={loading} />
    </div>
  );
};

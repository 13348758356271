import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { AppError, ExplorerFormInput, ExplorerSearchForm } from '../../component';
import * as gqlTypes from '../../apolloGenerated';
import { Redirect } from 'react-justanother-router';

import {
  isValidBtcAddress,
  isValidBtcBlockTransaction,
  isValidBtcTransaction,
  isValidEthAddress,
  isValidEthTransaction,
} from '@aml/validation';
import { RedirectProps } from 'react-justanother-router/src/Redirect';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../router';
import { openTransaction } from '@shared/libs';
import { Container } from '@shared/ui';
import { Spacer, toaster, Informer, InformerEnumType } from '@rubin-dev/goblin';
import styles from './explorer.module.scss';
import cx from 'classnames';

export type ExplorerScreenProps = {
  params: {
    network: string;
    value?: string;
  };
  callbackResult?: (hasResult: boolean) => void;
};

export const ExplorerSearchScreen: FC<PropsWithChildren<ExplorerScreenProps>> = ({
  params,
  callbackResult,
  children,
}) => {
  const { t } = useTranslation();
  const [noResult, setNoResult] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<RedirectProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const currencies: string[] = ['BTC', 'TRON', 'ETH'];

  const getCurrentNetwork = (): string => {
    switch (params.network) {
      case 'btc':
        return currencies[0];
      case 'tron':
        return currencies[1];
      case 'eth':
        return currencies[2];
      default:
        return currencies[0];
    }
  };

  const initialValues: ExplorerFormInput = {
    query: params.value || '',
    network: getCurrentNetwork(),
  };

  const [explorerFindTransactionByHash, { error: explorerFindTransactionByHashError }] =
    gqlTypes.useExplorerFindTransactionByHashLazyQuery();

  const [explorerFindBlockByNumber, { error: explorerFindBlockByNumberError }] =
    gqlTypes.useExplorerFindBlockByNumberLazyQuery();

  const [explorerFindBlockByHash, { error: explorerFindBlockByHashError }] =
    gqlTypes.useExplorerFindBlockByHashLazyQuery();

  const [explorerFindAddressByHash, { error: explorerFindAddressByHashError }] =
    gqlTypes.useExplorerFindAddressByHashLazyQuery();

  const ethCallback = useCallback((value: string) => {
    setLoading(true);
    if (isValidEthTransaction(value)) {
      explorerFindTransactionByHash({
        variables: {
          network: gqlTypes.Network.Eth,
          id: value,
        },
      }).then((resp) => {
        if (resp.data?.explorerFindTransactionByHash.transaction?.id) {
          openTransaction('eth', value);
          setNoResult(false);
        } else setNoResult(true);
        setLoading(false);
      });
    } else if (isValidEthAddress(value)) {
      explorerFindAddressByHash({
        variables: {
          network: gqlTypes.Network.Eth,
          address: value,
        },
      }).then((resp) => {
        if (resp.data?.explorerFindAddressByHash.node) {
          setRedirect({
            to: RouterName.ExplorerAddressDetails,
            params: { address: value, network: 'eth' },
          });
          setNoResult(false);
        } else {
          setNoResult(true);
          setLoading(false);
        }
      });
    } else if (!isNaN(Number(value))) {
      explorerFindBlockByNumber({
        variables: {
          network: gqlTypes.Network.Eth,
          height: parseInt(value, 10),
        },
      }).then((resp) => {
        if (resp.data?.explorerFindBlockByHeight.node) {
          setRedirect({
            to: RouterName.ExplorerBlock,
            params: { height: value, network: 'eth' },
          });
          setNoResult(false);
        } else {
          setNoResult(true);
          setLoading(false);
        }
      });
    } else {
      setNoResult(true);
      setLoading(false);
    }
  }, []);

  const tronCallback = useCallback((value: string) => {
    setLoading(true);
    if (value.length === 64) {
      explorerFindTransactionByHash({
        variables: {
          network: gqlTypes.Network.Tron,
          id: value,
        },
      }).then((resp) => {
        if (resp.data?.explorerFindTransactionByHash.transaction?.id) {
          openTransaction('tron', value);
          setNoResult(false);
        } else setNoResult(true);
        setLoading(false);
      });
    } else if (value.length === 34 || value.length === 42) {
      explorerFindAddressByHash({
        variables: {
          network: gqlTypes.Network.Tron,
          address: value,
        },
      }).then((resp) => {
        if (resp.data?.explorerFindAddressByHash.node) {
          setRedirect({
            to: RouterName.ExplorerAddressDetails,
            params: { address: value, network: 'tron' },
          });
          setNoResult(false);
        } else {
          setNoResult(true);
          setLoading(false);
        }
      });
    } else if (!isNaN(Number(value))) {
      explorerFindBlockByNumber({
        variables: {
          network: gqlTypes.Network.Tron,
          height: parseInt(value, 10),
        },
      }).then((resp) => {
        if (resp.data?.explorerFindBlockByHeight.node) {
          setRedirect({
            to: RouterName.ExplorerBlock,
            params: { height: value, network: 'tron' },
          });
          setNoResult(false);
        } else {
          setNoResult(true);
          setLoading(false);
        }
      });
    } else {
      setNoResult(true);
      setLoading(false);
    }
  }, []);

  const explorerCallback = useCallback((value: string) => {
    setLoading(true);
    if (!isNaN(Number(value)) && parseInt(value, 10)) {
      explorerFindBlockByNumber({
        variables: {
          network: gqlTypes.Network.Btc,
          height: parseInt(value, 10),
        },
      }).then((resp) => {
        if (resp.data?.explorerFindBlockByHeight.node) {
          setRedirect({
            to: RouterName.ExplorerBlock,
            params: { height: value, network: 'btc' },
          });
          setNoResult(false);
        } else {
          setNoResult(true);
          setLoading(false);
        }
      });
    } else if (isValidBtcBlockTransaction(value)) {
      explorerFindBlockByHash({
        variables: {
          network: gqlTypes.Network.Btc,
          hash: value,
        },
      }).then((resp) => {
        if (resp.data?.explorerFindBlockByHash.node) {
          setRedirect({
            to: RouterName.ExplorerBlockTransactions,
            params: { hash: value, network: 'btc' },
          });
          setNoResult(false);
        } else {
          setNoResult(true);
          setLoading(false);
        }
      });
    } else if (isValidBtcTransaction(value)) {
      explorerFindTransactionByHash({
        variables: {
          network: gqlTypes.Network.Btc,
          id: value,
        },
      }).then((resp) => {
        if (resp.data?.explorerFindTransactionByHash.transaction?.id) {
          openTransaction('btc', value);
          setNoResult(false);
        } else setNoResult(true);
        setLoading(false);
      });
    } else if (isValidBtcAddress(value)) {
      explorerFindAddressByHash({
        variables: {
          network: gqlTypes.Network.Btc,
          address: value,
        },
      }).then((resp) => {
        if (resp.data?.explorerFindAddressByHash.node) {
          setRedirect({
            to: RouterName.ExplorerAddressDetails,
            params: { address: value, network: 'btc' },
          });
          setNoResult(false);
        } else {
          setNoResult(true);
          setLoading(false);
        }
      });
    } else {
      setNoResult(true);
      setLoading(false);
    }
  }, []);

  const queryCallback = useCallback((value: string, network: string) => {
    if (!value || !network) return;
    setLoading(true);
    if (network === 'BTC') {
      explorerCallback(value);
    } else if (network === 'ETH') {
      ethCallback(value);
    } else if (network === 'TRON') {
      tronCallback(value);
    } else {
      toaster.error({ title: 'invalid network' });
      setNoResult(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (redirect && loading) {
      setLoading(false);
    }
  }, [redirect, loading]);

  useEffect(() => {
    if (callbackResult) {
      callbackResult(noResult);
    }
  }, [noResult]);

  const getInformData = (): JSX.Element => {
    const information = {
      empty: {
        type: 'empty',
        color: '#394363',
        title: t('strings.noresults'),
        subtitle: t('explorer.noResultFound'),
      },
      info: {
        type: 'info',
        color: '#394363',
        title: '',
        subtitle: t('explorer.find'),
      },
    };
    const data = noResult ? information['empty'] : information['info'];

    return (
      <div className={styles['explorer__wrapper']}>
        <div
          className={cx(
            styles['explorer__content-wrapper'],
            styles['explorer__content-wrapper_empty'],
          )}
        >
          <Informer
            type={data.type as InformerEnumType}
            color={data.color}
            title={data.title}
            subtitle={data.subtitle}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {redirect && <Redirect to={redirect.to} params={redirect.params} />}
      <div className={styles.explorer__container}>
        <Container>
          <ExplorerSearchForm
            currencies={currencies}
            initialValues={initialValues}
            loading={loading}
            address={params.value}
            formOnSubmit={(values) => queryCallback(values.query, values.network)}
          />
        </Container>
        {!params.value || noResult ? (
          getInformData()
        ) : explorerFindTransactionByHashError ||
          explorerFindBlockByNumberError ||
          explorerFindBlockByHashError ||
          explorerFindAddressByHashError ? (
          <AppError error={t('errors.reloading')} />
        ) : (
          children
        )}
      </div>
    </>
  );
};

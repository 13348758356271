import React, { FC, useMemo, useState } from 'react';
import {
  Typography,
  Button,
  TableController,
  Pagination,
  LoaderOverlay,
  TrashIcon,
  CopyHash,
  Informer,
} from '@rubin-dev/goblin';
import { formatDate } from '@utils/timestamp';
import { Link } from 'react-justanother-router';
import { RouterName } from '../../router/types';
import { useTranslation } from 'react-i18next';
import { getNetworkEnum } from '@helpers/address';
import { Finder } from '@shared/ui';
import { useSubscriptionStore } from '../../component/Mention';
import { router } from '../../router';
import * as gqlTypes from '@apolloGenerated';
import cx from 'classnames';
import styles from './styles/markup.module.scss';

export const RequestsTab: FC = () => {
  const { t } = useTranslation();

  const { unsubscribeAction } = useSubscriptionStore();

  const headers = [
    {
      title: 'date',
      value: 'date',
      width: 110,
    },
    {
      title: '',
      value: 'hash',
    },
    {
      title: '',
      value: 'status',
      width: 90,
    },
    {
      title: '',
      value: 'actions',
      width: 40,
    },
  ];

  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);

  const { data, loading, refetch } = gqlTypes.useSubscriptionListQuery({
    variables: {
      page,
      pageSize,
    },
    fetchPolicy: 'network-only',
  });

  const onUnsubscribe = async (network: gqlTypes.Network, address: string) => {
    await unsubscribeAction(network, address);
    refetch({
      page,
      pageSize,
    });
  };

  const openFinder = (val: string) => {
    window.open(
      router.urlFor(RouterName.Markup, {}, { query: val }),
      '_blank',
      'noopener,noreferrer',
    );
  };

  const renderDate = (timestamp: string) => {
    return (
      <div>
        <Typography variant={'body-16'} className={styles['markup__text']}>
          {formatDate(timestamp, true)}
        </Typography>
      </div>
    );
  };

  const renderStatus = (hasResult: boolean) =>
    hasResult ? (
      <Link
        to={RouterName.Markup}
        query={{ query: '' }}
        className={styles['markup__link']}
      >
        {t('markup.result')}
      </Link>
    ) : (
      <Typography variant={'body-14'} className={styles['markup__text']}>
        {t('markup.inProgress')}
      </Typography>
    );

  const renderHash = (address: string) => {
    return (
      <div className={styles['markup__text-group']}>
        <CopyHash hash={address} onClickHash={() => openFinder(address)} shorted />
      </div>
    );
  };

  const renderActions = (network: string, address: string) => {
    return (
      <Button
        variant={'text'}
        prependIcon={TrashIcon}
        icon
        size={'small'}
        className={styles['markup__btn-icon']}
        onClick={() => onUnsubscribe(getNetworkEnum(network), address)}
      />
    );
  };

  const total = useMemo(
    () => data?.subscriptionList?.total || 0,
    [data?.subscriptionList?.edge],
  );

  const list = useMemo(
    () =>
      data?.subscriptionList?.edge.map((item) => ({
        date: renderDate(item.createdAt),
        hash: renderHash(item.address),
        status: renderStatus(item.hasResult),
        actions: renderActions(item.network, item.address),
      })) || [],
    [data?.subscriptionList?.edge],
  );

  return (
    <div
      className={cx(
        styles['markup__card'],
        list.length && styles['markup__card_vertical-top'],
      )}
    >
      {list.length ? (
        <div className={cx(styles['markup__table-wrapper'])}>
          <TableController headers={headers} data={list} />
          {total > pageSize && (
            <Pagination
              page={page}
              pageCount={Math.ceil(total / pageSize)}
              onChange={setPage}
            />
          )}
        </div>
      ) : (
        <div className={styles['markup__not-found']}>
          <Informer
            type="empty"
            title={t('strings.noresults')}
            subtitle={t('titles.noRequestsSent')}
          />
        </div>
      )}
      <LoaderOverlay show={loading} />
    </div>
  );
};

import React, { FC, useMemo, useState } from 'react';
import {
  ArrowLeftLongIcon,
  ArrowRightLongIcon,
  CopyHash,
  Divider,
  Driver,
  DriverHeader,
  Spacer,
  TableController,
  Typography,
  WithCloseModal,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import {
  Address,
  AddressTransaction,
  AddressTransactionsFilterTxType,
  Network,
} from '@apolloGenerated';
import { ListItem, renderCellAmount, renderCellHash } from '@shared/ui';
import { timestampToHumanDate } from '@utils/timestamp';
import { openTransaction, openWallet } from '@shared/libs';
import styles from './styles.module.scss';
import cx from 'classnames';
import { getClusterLabel } from '@helpers/address';

export type ExplorerTransactionDriverProps = {
  transaction: AddressTransaction;
  network: Network;
};

declare global {
  interface ModalProps {
    explorerTransaction: ExplorerTransactionDriverProps;
  }
}
const pageSize = 10;
export const ExplorerTransactionDriver: FC<
  WithCloseModal<ExplorerTransactionDriverProps>
> = ({
  onClose,
  transaction: { token, timestamp, txID, direction, recvAddr, senderAddr, amount },
  network,
}) => {
  const { t } = useTranslation();
  const isSend = direction === 'sends';

  const renderArrowIcon = (type: AddressTransactionsFilterTxType): JSX.Element => {
    return (
      <div className={styles.trDriver__tr_center}>
        <ArrowRightLongIcon
          color={
            type === AddressTransactionsFilterTxType.Sent
              ? 'var(--error-2)'
              : 'var(--success-2)'
          }
        />
      </div>
    );
  };
  const rowTemplate = ({ address, cluster }: Address) => {
    const type = isSend
      ? AddressTransactionsFilterTxType.Sent
      : AddressTransactionsFilterTxType.Receives;
    return {
      hashUniq: address,
      hash: (
        <div>
          {renderCellHash({
            hash: address,
            type: 'address',
            network,
          })}
          {cluster && (
            <Typography variant="body-12" color="on-surface-secondary-2">
              {t('mention.cluster')}:{' '}
              <Typography
                tag="span"
                variant="body-12"
                color="text-link"
                className="cursor-pointer"
                onClick={() => openWallet(network, cluster!.id)}
              >
                {getClusterLabel(cluster)}
              </Typography>
            </Typography>
          )}
        </div>
      ),
      amount: renderCellAmount({
        amount,
        network,
        type,
        style: {
          width: '102px',
        },
      }),
      arrow_right: renderArrowIcon(type),
      arrow_left: renderArrowIcon(type),
    };
  };

  const arrowRight = (hide: boolean) => ({
    value: 'arrow_right',
    width: 24,
    className: styles.trDriver__tr_noPadding,
    hide,
  });
  const arrowLeft = (hide: boolean) => ({
    value: 'arrow_left',
    width: 24,
    className: styles.trDriver__tr_noPadding,
    hide,
  });
  const amountCell = (hide: boolean) => ({
    title: t('strings.amount'),
    value: 'amount',
    hide,
    width: 116,
  });
  const headersSending = [
    { title: t('strings.sendingAddress'), value: 'hash' },
    arrowRight(!isSend),
    amountCell(!isSend),
    arrowLeft(!isSend),
    { title: '', value: 'hashUniq', key: true, hide: true },
  ];
  const totalSending = senderAddr.length;
  const [sendingPage, setSendingPage] = useState<number>(1);
  const itemsSending = useMemo(
    () =>
      [...senderAddr]
        .slice(pageSize * (sendingPage - 1), pageSize * sendingPage)
        .map(rowTemplate),
    [sendingPage],
  );

  const headersReceiving = [
    arrowRight(isSend),
    amountCell(isSend),
    arrowLeft(isSend),
    { title: t('strings.receivingAddress'), value: 'hash' },
    { title: '', value: 'hashUniq', key: true, hide: true },
  ];
  const totalReceiving = recvAddr.length;
  const [receivingPage, setReceivingPage] = useState<number>(1);
  const itemsReceiving = useMemo(
    () =>
      [...recvAddr]
        .slice(pageSize * (receivingPage - 1), pageSize * receivingPage)
        .map(rowTemplate),
    [receivingPage],
  );

  return (
    <Driver
      width={627}
      headerSlot={<DriverHeader titleSlot={t('links.transaction')} onClose={onClose} />}
      className={styles.trDriver}
    >
      <ListItem
        label={`${t('titles.token')}:`}
        value={token ? token.toUpperCase() : network}
        row
      />
      <Spacer size={2} />
      <ListItem
        label={'Date (UTC):'}
        value={`${timestampToHumanDate(timestamp, 'date')}, 
            ${timestampToHumanDate(timestamp, 'time')}`}
        row
      />
      <Spacer size={2} />
      <ListItem
        label={`${t('titles.hash')}:`}
        value={
          <CopyHash
            hash={txID}
            variant="head-16"
            onClickHash={() => openTransaction(network, txID)}
            shorted
          />
        }
        row
      />
      <Spacer size={28} />
      <div
        className={cx(
          styles.trDriver__tableWrapper,
          isSend && styles.trDriver__tableWrapper_revert,
        )}
      >
        <TableController
          data={itemsSending}
          headers={headersSending}
          total={totalSending}
          onChangePage={setSendingPage}
          pageSize={pageSize}
          notResetPage
        />
        <Divider vertical />
        <TableController
          data={itemsReceiving}
          headers={headersReceiving}
          total={totalReceiving}
          onChangePage={setReceivingPage}
          pageSize={pageSize}
          notResetPage
        />
      </div>
    </Driver>
  );
};

import React, { FC, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import styles from './styles/markup.module.scss';
import { LoaderOverlay } from '@rubin-dev/goblin';

export type ScreenshotsType = {
  screenshots: string[];
  loading: boolean;
};

export const Screenshots: FC<ScreenshotsType> = ({ screenshots, loading }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = screenshots.map((screen) => `data:image/png;base64,${screen}`);

  const openImageViewer = (index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <div className={styles['markup__screenshots']}>
        {screenshots.map((screen, i) => (
          <div key={i} onClick={() => openImageViewer(i)} role="button" tabIndex={0}>
            <img
              className={styles['markup__screenshot-item']}
              src={`data:image/png;base64,${screen}`}
              alt={String(i)}
            />
          </div>
        ))}
        <LoaderOverlay show={loading} />
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

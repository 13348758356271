import { FC } from 'react';
import { Button, ExportDenseIcon } from '@rubin-dev/goblin';
import { useGraphIntegration } from '@graph/libs/hooks';

export const GraphExportButton: FC = () => {
  const [{ exportJSON }] = useGraphIntegration();

  return (
    <Button
      variant="outlined"
      icon
      prependIcon={ExportDenseIcon}
      onClick={() => exportJSON()}
    />
  );
};

import React, { FC } from 'react';
import { Address } from '@apolloGenerated';
import { TableCellHash } from '@shared/ui';
import styles from './styles.module.scss';
import { Typography } from '@rubin-dev/goblin';

export type TableCellReceiveAddressesProps = {
  items: Address[];
  network: string;
  showCounter?: boolean;
  onClickCounter?(): void;
};
export const TableCellReceiveAddresses: FC<TableCellReceiveAddressesProps> = ({
  items,
  network,
  showCounter = false,
  onClickCounter,
}) => {
  const count = items.length;
  const address = items[0].address;

  return (
    <div className={styles.cellReceive}>
      <TableCellHash key={address} hash={address} network={network} type="address" />
      {showCounter && count > 1 && (
        <Typography
          className={styles.cellReceive__count}
          variant="body-12"
          color="on-surface-primary-1"
          onClick={onClickCounter}
        >
          +{count - 1}
        </Typography>
      )}
    </div>
  );
};

export const renderCellReceiveAddresses = (args: TableCellReceiveAddressesProps) => (
  <TableCellReceiveAddresses {...args} />
);

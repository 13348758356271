import React, { FC, JSX, useEffect, useMemo, useState } from 'react';
import { getNetworkEnum } from '@helpers/address';
import { ExplorerClusterAddresses } from '../../component';
import { ClusterMainInfo } from '@component/Address';
import { useTranslation } from 'react-i18next';
import { useExplorerClusterQuery, useMeQuery } from '@apolloGenerated';
import {
  Spacer,
  TabItem,
  Tabs,
  Loader,
  Button,
  Typography,
  VisualizationDenseIcon,
} from '@rubin-dev/goblin';
import styles from './explorer-address.module.scss';
import { openVisualizationAddress } from '@shared/libs';
import { RouterName } from '../../router';
import { RiskDot } from '@component/NewRisk';
import { Blur } from '@shared/ui';

type ExplorerWalletScreenProps = {
  params: {
    network: string;
    wid: string;
  };
};

export const ExplorerWalletScreen: FC<ExplorerWalletScreenProps> = ({
  params: { network, wid },
}): JSX.Element => {
  const { data: user } = useMeQuery({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();
  const [total, setTotal] = useState<string | number>(0);
  const { data, loading } = useExplorerClusterQuery({
    variables: { network: getNetworkEnum(network), id: wid },
  });
  const stats = data?.explorerClusterStats;
  const owner = data?.explorerCluster?.cluster?.owner || 'Entity';

  useEffect(() => {
    setTotal(stats?.stats?.addressCount || 0);
  }, [stats?.stats?.addressCount]);

  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );
  return (
    <>
      <div className={styles.explorerAddress__head}>
        {expireDate ? (
          <Typography variant="head-20" color="on-surface-primary-1">
            {loading ? <Loader /> : owner}
          </Typography>
        ) : (
          <Blur to={RouterName.TariffAnalyzer}>
            <Typography variant="head-20" color="on-surface-primary-1">
              Owner
            </Typography>
          </Blur>
        )}
        <Button
          icon
          size="small"
          prependIcon={() => VisualizationDenseIcon({ color: '#394363' })}
          onClick={() => openVisualizationAddress(network, wid)}
          variant="outlined"
        />
      </div>
      <Spacer size={8} />
      <ClusterMainInfo wid={wid} network={getNetworkEnum(network)} hideCluster />
      <Spacer size={16} />
      <Tabs initValue="addresses">
        <TabItem value="addresses">
          <div>
            {t('links.addresses')}
            {loading ? <Loader size={16} /> : ` (${total})`}
          </div>
        </TabItem>
      </Tabs>
      <Spacer size={8} />
      <ExplorerClusterAddresses wid={wid} network={network} updateTotal={setTotal} />
    </>
  );
};

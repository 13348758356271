import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getNetworkEnum } from '@helpers/address';
import {
  Spacer,
  LoaderOverlay,
  TableController,
  Toggle,
  type ToggleItem,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import {
  useExplorerFindWalletAddressesCountQuery,
  useExplorerFindWalletAddressesQuery,
  WalletAddressesOrderField,
  OrderDirection,
} from '@apolloGenerated';
import styles from './styles.module.scss';
import { renderCellHash } from '@shared/ui';

export type BtcAddressTableProps = {
  wid: string;
  network: string;
  updateTotal?: (total: number | string) => void;
};

const pageSize = 10;
const order = {
  field: WalletAddressesOrderField.LastActivity,
  direction: OrderDirection.Desc,
};
export const ExplorerClusterAddresses: FC<BtcAddressTableProps> = ({
  wid,
  network,
  updateTotal,
}) => {
  const networkEnum = getNetworkEnum(network);
  const { t } = useTranslation();
  const [reportedOnly, setReportedOnly] = useState(false);

  const [page, setPage] = useState(1);

  const {
    loading: isLoadingWallet,
    data: walletAddress,
    previousData: prevWalletAddress,
  } = useExplorerFindWalletAddressesQuery({
    variables: {
      network: networkEnum,
      filter: {
        wid,
        pageSize,
        page,
        reportedOnly,
        order,
      },
    },
  });
  const {
    data: totalData,
    previousData: prevTotalData,
    loading: isLoadingTotal,
  } = useExplorerFindWalletAddressesCountQuery({
    variables: {
      network: networkEnum,
      filter: { wid, reportedOnly },
    },
  });

  const items = useMemo(
    () =>
      (
        walletAddress?.explorerFindWalletAddresses?.edge ||
        prevWalletAddress?.explorerFindWalletAddresses?.edge ||
        []
      ).map(({ address }) => {
        return {
          address: renderCellHash({
            hash: address,
            type: 'address',
            network,
            longer: true,
          }),
          key: address,
        };
      }),
    [walletAddress],
  );
  const total = useMemo(
    () =>
      totalData?.explorerFindWalletAddressesCount.total ||
      prevTotalData?.explorerFindWalletAddressesCount.total ||
      0,
    [totalData],
  );

  useEffect(() => {
    if (updateTotal) {
      updateTotal(total);
    }
  }, [total]);

  const onOptionChange = useCallback((val: string) => {
    setReportedOnly(val === 'reported');
  }, []);

  const listButtons: ToggleItem<'all' | 'reported'>[] = [
    { value: 'all', label: t('strings.all') },
    { value: 'reported', label: t('strings.reported') },
  ];

  const headers = [
    { title: t('titles.address'), value: 'address' },
    { hide: true, key: true, value: 'key' },
  ];

  return (
    <>
      <Toggle
        initValue={reportedOnly ? 'reported' : 'all'}
        items={listButtons}
        onChange={onOptionChange}
        size="small"
      />
      <Spacer size={8} />
      <div className={styles.clusterAddresses__content}>
        <TableController
          data={items}
          headers={headers}
          total={Number(total)}
          onChangePage={setPage}
          pageSize={pageSize}
        />
      </div>
      <LoaderOverlay show={isLoadingWallet || isLoadingTotal} />
    </>
  );
};

import { FC, useMemo, useState } from 'react';
import { LoaderOverlay, Spacer, TableController, Toggle } from '@rubin-dev/goblin';
import {
  renderCellUTXO,
  SortParams,
  TABLE_TRANSACTION_TABS,
  Transaction,
  useSidebarData,
  useSidebarEvents,
  useSidebarTrTabs,
} from '@graph-sidebar-module/shared';
import { TableType } from '@graph/types';
import {
  AddressTransaction,
  AddressTransactionsFilterTxType,
  AddressTransactionsOrderField,
  OrderDirection,
  useExplorerFindTransactionsByAddressQuery,
  useGraphStatsAddressByHashQuery,
} from '@apolloGenerated';
import {
  renderCellAmount,
  renderCellCheckbox,
  renderCellChevron,
  renderCellDate,
  renderCellHash,
  renderCellToken,
} from '@shared/ui';
import { PAGE_SIZE, INIT_SORT, INIT_SORT_BY, headers } from '../../conts';
import { AddressTable } from '@graph/models';

export const AddressTransactionsTable: FC = () => {
  const { network, hash, checked } = useSidebarData();
  const { onChangeChecked, onClickChevron } = useSidebarEvents();
  const [tab, setTab] = useSidebarTrTabs();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<SortParams>({
    sort: INIT_SORT,
    sortBy: INIT_SORT_BY,
  });

  const { data: statsData } = useGraphStatsAddressByHashQuery({
    variables: {
      network,
      address: hash,
    },
  });
  const { data, previousData, loading } = useExplorerFindTransactionsByAddressQuery({
    variables: {
      network,
      filter: {
        address: hash,
        page,
        order: sort.sort
          ? {
              field: sort.sortBy as AddressTransactionsOrderField,
              direction: sort.sort as OrderDirection,
            }
          : null,
        txType: tab,
        pageSize: PAGE_SIZE,
      },
    },
  });
  const rowTemplate = (transaction: AddressTransaction) => {
    let checkboxRender: JSX.Element | string;
    const item = new Transaction(transaction, network, TableType.Address, true);
    const checkedItem = AddressTable.getCheckedItem(
      item.txid,
      item.type,
      item.sourceAddress!,
      item.targetAddress!,
    );
    if (AddressTable.hasChecked(checked, checkedItem)) item.setChecked(true);

    if (item.isUTXO) checkboxRender = renderCellUTXO();
    else if (item.sourceAddress === item.targetAddress) checkboxRender = ' ';
    else if (item.isDirectTransfer)
      checkboxRender = renderCellCheckbox({
        checked: item.checked,
        onChange: (val) => onChangeChecked(val, item),
      });
    else checkboxRender = renderCellChevron({ onClick: () => onClickChevron(item) });

    return {
      hashUniq: AddressTable.getCheckedHash(checkedItem),
      checkbox: checkboxRender,
      timestamp: renderCellDate({ timestamp: item.timestamp! }),
      hash: renderCellHash({ hash: item.txid, type: 'transaction', network }),
      token: renderCellToken({ token: item.token }),
      amount: renderCellAmount({ amount: item.amount!, network, type: item.type }),
    };
  };
  const items = useMemo(
    () =>
      (
        data?.explorerFindTransactionsByAddress.edge ||
        previousData?.explorerFindTransactionsByAddress.edge ||
        []
      ).map(rowTemplate),
    [data, rowTemplate],
  );
  const stats = statsData?.explorerAddressTransactionStats?.stats;
  const total = useMemo(
    () =>
      tab === AddressTransactionsFilterTxType.All
        ? stats?.total
        : tab === AddressTransactionsFilterTxType.Receives
        ? stats?.received
        : stats?.sent,
    [stats?.total, tab],
  );
  const hasTabs = !!(
    Number(statsData?.explorerAddressTransactionStats?.stats?.received) &&
    Number(statsData?.explorerAddressTransactionStats?.stats?.sent)
  );
  return (
    <div className="relative">
      {loading && <LoaderOverlay show />}
      {hasTabs && (
        <>
          <Toggle
            initValue={tab}
            items={TABLE_TRANSACTION_TABS()}
            onChange={setTab}
            size="small"
          />
          <Spacer size={12} />
        </>
      )}
      <TableController
        data={items}
        headers={headers()}
        total={Number(total || 0)}
        pageSize={PAGE_SIZE}
        initSort={INIT_SORT}
        initSortBy={INIT_SORT_BY}
        onChangePage={setPage}
        onSort={(sort, sortBy) => setSort({ sort, sortBy })}
      />
    </div>
  );
};

import { useState, useEffect, useCallback } from 'react';
import { LinkList, Meta, NodeList, TableController } from '@graph/models';
import { FiguresType } from '@rubin-dev/octavius';
import { cloneDeep } from 'lodash';
import {
  HandleUpdateFn,
  UpdateHashParams,
  UseGraphData,
  UseGraphDataMethods,
  UseGraphDataPrevHashes,
  UseGraphDataValues,
} from './types';
import { GraphHashes } from '@graph/types/graph';
import {
  SaveCanvasDataEventParams,
  UpdatedDataEventParams,
  useGraphStatusStore,
} from '../../store';
import { useGraphIntegration } from '@graph/libs/hooks';

export const useGraphData: UseGraphData = ({ initHash, initType }) => {
  // HOOKS
  const [{ updateData }] = useGraphIntegration();

  // HASHES
  const [hashes, setHashes] = useState<GraphHashes & UseGraphDataPrevHashes>({
    hash: initHash,
    prevHash: initHash,
    type: initType,
    prevType: initType,
  });

  // STORE
  const { isInitialized, setInitialized } = useGraphStatusStore();

  // DATA
  const [tableData, setTableData] = useState<UseGraphDataValues['tableData']>(null);
  const [nodes, setNodes] = useState<UseGraphDataValues['nodes']>([]);
  const [links, setLinks] = useState<UseGraphDataValues['links']>([]);

  useEffect(() => {
    Meta.current = { hash: initHash, type: initType };
  }, []);

  const handleUpdateCanvas: UseGraphDataMethods['onUpdateNodes'] = useCallback(
    (canvas) => {
      if (canvas) Meta.canvas = canvas;
    },
    [],
  );

  const handleUpdateData: UseGraphDataMethods['onUpdateData'] = useCallback(() => {
    const tableData = TableController.getTableDataByHash(hashes.hash, hashes.type);
    setTableData(tableData);
    const nodes = NodeList.getDataForGraph(hashes.hash);
    setNodes(cloneDeep(nodes));
    const links = LinkList.getLinksForGraph(hashes.hash);
    setLinks(cloneDeep(links));
    if (!isInitialized) setInitialized(true);
  }, [isInitialized, setInitialized, hashes]);

  // HASH UPDATER WITH HISTORY
  const handleUpdateHash: UseGraphDataMethods['onUpdateHash'] = useCallback(
    ({ hash, type }) => {
      setHashes((state) => ({
        prevType: state.type,
        prevHash: state.hash,
        hash,
        type,
      }));
      Meta.current = { hash, type };
    },
    [setHashes],
  );

  const handleUpdateNodeCoords = useCallback(({ uuid, x, y }: FiguresType) => {
    NodeList.hashList[uuid]?.setCoords(x!, y!);
  }, []);

  const handleUpdateMeta: UseGraphDataMethods['onUpdateMeta'] = useCallback(
    (nodes) => {
      if (Array.isArray(nodes)) {
        if (!nodes?.length) return;
        const lastNode = [...nodes].pop();
        handleUpdateNodeCoords(lastNode!);
      } else {
        handleUpdateNodeCoords(nodes);
      }
    },
    [handleUpdateNodeCoords],
  );

  const handleUpdate: HandleUpdateFn = useCallback(
    (type) => (params) => {
      switch (type) {
        case 'data':
          handleUpdateData();
          break;
        case 'canvas':
          handleUpdateCanvas(params as SaveCanvasDataEventParams);
          break;
        case 'meta':
          handleUpdateMeta(params as UpdatedDataEventParams);
          break;
        case 'hash':
          handleUpdateHash(params as UpdateHashParams);
          break;
      }
      if (isInitialized) updateData();
    },
    [
      updateData,
      isInitialized,
      handleUpdateData,
      handleUpdateCanvas,
      handleUpdateMeta,
      handleUpdateHash,
    ],
  );

  return [
    {
      onUpdateData: handleUpdate('data'),
      onUpdateMeta: handleUpdate('meta'),
      onUpdateNodes: handleUpdate('canvas'),
      onUpdateHash: handleUpdate('hash'),
    },
    {
      links,
      nodes,
      tableData,
      canvas: Meta.canvas,
      ...hashes,
    },
  ];
};

import { Node } from './Node';
import { NodeItem } from '../../types/graph';
import { AddressTable } from '@graph/models';

export class NodeList {
  static hashList: Record<string, Node> = {};
  static tableHashList: Record<string, AddressTable> = {};

  static create(addressItem: Node, addressTable: AddressTable, rewrite?: boolean) {
    const address = addressItem.hash;
    if (NodeList.hashList[address] && !rewrite) return;
    NodeList.hashList[address] = addressItem;
    NodeList.tableHashList[addressItem.tableHash] = addressTable;
  }

  static remove(hash: string) {
    const tableItem = NodeList.hashList[hash];
    if (!tableItem) return;
    delete NodeList.hashList[hash];
    delete NodeList.tableHashList[tableItem.hash];
  }

  static getTableDataByHash(hash: string): AddressTable {
    const tableDataHash = NodeList.hashList[hash]?.tableHash;
    return NodeList.tableHashList[tableDataHash];
  }

  static getDataForGraph(currentHash: string): NodeItem[] {
    const addressItems: Node[] = Object.values(NodeList.hashList);
    return addressItems.reduce<NodeItem[]>((acc, el) => {
      el.setNode();
      if (currentHash === el.hash) {
        el.activateNode();
        el.rerenderNode();
      }
      if (el.node) acc.push(el.node);
      return acc;
    }, []);
  }

  static getCountAddresses() {
    return Object.keys(NodeList.hashList).length;
  }
}

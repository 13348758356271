import { FC, useState } from 'react';
import { formatDate } from '@utils/timestamp';
import { Calendar, Typography } from '@rubin-dev/goblin';
import { DateTime } from 'luxon';
import { hasPermission } from '@utils/auth';
import {
  BILLING_ANALYZER_SUBSCRIPTION_CHANGE,
  USER_ADMIN,
} from '@constants/permissions.const';

interface Props {
  subscriptionExpiryDate: string;
  onChangeDate?: (date: DateTime[]) => void;
  disabled?: boolean;
  confirmButton?: boolean;
}

export const ActivateUser: FC<Props> = ({
  subscriptionExpiryDate,
  onChangeDate,
  disabled = false,
  confirmButton = false,
}) => {
  const [selectedDate] = useState<DateTime[]>(
    subscriptionExpiryDate && subscriptionExpiryDate
      ? [
          DateTime.fromISO(subscriptionExpiryDate).toUTC(),
          DateTime.fromISO(subscriptionExpiryDate).toUTC(),
        ]
      : [],
  );
  const onChangeDateHandler = (date: DateTime[]) => {
    if (!onChangeDate) return;

    if (date.length === 1) {
      onChangeDate([...date, ...date]);
      return;
    }

    onChangeDate(date);
  };

  return (
    <div>
      {hasPermission(USER_ADMIN) ? (
        <Calendar
          disabled={disabled}
          confirmButton={confirmButton}
          selectedRange={selectedDate}
          selectedDate={selectedDate?.length ? selectedDate[0] : undefined}
          onChange={onChangeDateHandler}
        />
      ) : (
        subscriptionExpiryDate && (
          <Typography variant={'body-14'}>
            {formatDate(subscriptionExpiryDate, true)}
          </Typography>
        )
      )}
    </div>
  );
};

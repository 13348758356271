import { FC } from 'react';
import {
  useSidebarWindow,
  GraphWindow,
  useSidebarData,
} from '@graph-sidebar-module/shared';
import { TokenSelect } from '@graph-sidebar-module/features/Token';
import { CopyHash, Divider, DriverHeader, Spacer, Typography } from '@rubin-dev/goblin';
import { openAddress } from '@shared/libs';
import { useTranslation } from 'react-i18next';
import {
  AddressInfo,
  AddressTransactionsTable,
} from '@graph-sidebar-module/entities/Address';

export const AddressWindow: FC = () => {
  const { t } = useTranslation();
  const { hash, network, isLoading } = useSidebarData();
  const [{ onHide }, { isShow }] = useSidebarWindow();

  return (
    <GraphWindow
      isOpen={isShow}
      onClose={onHide}
      width={664}
      isLoading={isLoading}
      headerSlot={
        <DriverHeader
          titleSlot={
            <CopyHash
              hash={hash}
              variant="head-24"
              shorted
              onClickHash={() => openAddress(network, hash)}
            />
          }
          // rightSlot={<TokenSelect />}
        />
      }
    >
      <AddressInfo address={hash} network={network} />
      <Spacer size={20} />
      <Typography variant="head-20" color="on-surface-primary-1">
        {t('strings.transactions')}
      </Typography>
      <Spacer size={8} />
      <Divider />
      <Spacer size={12} />
      <AddressTransactionsTable />
    </GraphWindow>
  );
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  LoaderOverlay,
  TableController,
  toaster,
  Typography,
  EditIcon,
} from '@rubin-dev/goblin';
import { AppError } from '../../component';
import { useTranslation } from 'react-i18next';
import {
  useActivateMutation,
  useGeneratePasswordMutation,
  User,
  useUserListQuery,
} from '@apolloGenerated';
import styles from './styles/userlist.module.scss';
import {
  API_DASHBOARD,
  SUBSCRIPTION_EXPLORER_MARKUP,
} from '@constants/permissions.const';
import { useModal } from '@hooks/useModal';
import { HEADER_TABLE_USERS } from './constants';
import { RightIcon, CodeIcon } from '@shared/ui';
import { formatDate } from '@utils/timestamp';

export type RolePermissionScreenProp = {
  query: {
    page?: number;
    pageSize?: number;
  };
};

export const UserListScreen: FC<RolePermissionScreenProp> = ({ query }): JSX.Element => {
  const { t } = useTranslation();
  const [userID, setUserID] = useState(-1);
  const [page, setPage] = useState(query?.page || 1);
  const pageSize = query?.pageSize || 50;

  const openEditUserDialog = useModal('editUserDialog');

  const { data, previousData, loading, refetch, error } = useUserListQuery({
    variables: {
      page,
      pageSize,
    },
  });

  const [mutate, activate] = useActivateMutation({});
  const [generateMutate, generateData] = useGeneratePasswordMutation({});

  const onSuccess = useCallback(() => {
    refetch({
      page,
      pageSize,
    });
  }, []);

  const activateUser = async (userId: number) => {
    setUserID(userId);
    await mutate({
      variables: {
        input: {
          userId,
        },
      },
    });
    setUserID(-1);
  };

  const generate = async (userId: number) => {
    setUserID(userId);

    await generateMutate({
      variables: {
        input: {
          userId,
        },
      },
    });

    setUserID(-1);
  };

  const total = useMemo(
    () => data?.userList.total || previousData?.userList.total || 0,
    [data],
  );

  const openDialog = (params: User) => {
    openEditUserDialog({
      name: params?.name,
      email: params?.email,
      userId: params.id,
      commonData: {
        billing: params.billing,
        permissions: params.permissions,
      } as User,
      onSuccess,
    });
  };

  const renderNameEmail = (name: string, email: string): JSX.Element => {
    return (
      <div>
        <Typography variant={'body-14'} color={'on-surface-primary-1'}>
          {name}
        </Typography>
        <Typography variant={'body-12'} color={'on-surface-primary-2'}>
          {email}
        </Typography>
      </div>
    );
  };

  const items = useMemo(
    () =>
      (data?.userList.edge || previousData?.userList.edge || []).map((item) => {
        if (activate.error && userID === item.id)
          toaster.error({ title: activate.error?.message });
        if (generateData.error && userID === item.id)
          toaster.error({ title: generateData.error?.message });

        return {
          id: item.id,
          nameEmail: renderNameEmail(item.name, item.email),
          e_1: ' ',
          enableProducts: (
            <div className={styles.userList__icons}>
              {item.permissions.includes(SUBSCRIPTION_EXPLORER_MARKUP) && <CodeIcon />}
              {item.permissions.includes(API_DASHBOARD) && <RightIcon />}
            </div>
          ),
          e_2: ' ',
          subscriptionAnalyzer: (
            <>
              <Typography variant={'body-14'} color={'on-surface-primary-1'}>
                {!item.billing.analyzerSubscriptionExpireDate
                  ? t('titles.free')
                  : t('titles.to') +
                    ' ' +
                    formatDate(item.billing.analyzerSubscriptionExpireDate, true)}
              </Typography>
            </>
          ),
          e_3: ' ',
          checksMarkup: (
            <Typography variant={'body-14'} color={'on-surface-primary-1'}>
              {item.billing.markup || '-'}
            </Typography>
          ),
          e_4: ' ',
          checksAPIAddress: (
            <Typography variant={'body-14'} color={'on-surface-primary-1'}>
              {item.billing.address || '-'}
            </Typography>
          ),
          e_5: ' ',
          checksAPITransaction: (
            <Typography variant={'body-14'} color={'on-surface-primary-1'}>
              {item.billing.transactions || '-'}
            </Typography>
          ),
          e_6: ' ',
          actions: (
            <Button
              prependIcon={EditIcon}
              icon
              size={'small'}
              className={styles['userList__action-btn']}
              variant={'outlined'}
              onClick={() => openDialog(item)}
            />
          ),
        };
      }),
    [data, onSuccess, userID, setUserID, activate, activateUser, generate],
  );

  useEffect(() => {
    if (generateData.data && !generateData.data?.generatePassword.errors)
      toaster.success({ title: t('titles.success') });
  }, [generateData.data?.generatePassword.errors]);

  useEffect(() => {
    if (activate.data && !activate.data?.activate.errors)
      toaster.success({ title: t('titles.success') });

    if (activate.data && activate.data?.activate.errors)
      toaster.error(activate.data?.activate.errors.activateUser[0]);
  }, [activate.data?.activate.errors]);

  if (error) {
    return <AppError error={error.message} />;
  }

  return (
    <section className={styles.userList__container}>
      <div className={styles.userList__wrapper}>
        <LoaderOverlay show={loading} />
        <TableController
          data={items}
          itemKey={'id'}
          headers={HEADER_TABLE_USERS}
          total={total}
          onChangePage={setPage}
          pageSize={pageSize}
          notResetPage
        />
      </div>
    </section>
  );
};

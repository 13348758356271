import React, { FC, useLayoutEffect, useMemo } from 'react';
import {
  TableController,
  Typography,
  LoaderOverlay,
  Button,
  CopyHash,
} from '@rubin-dev/goblin';
import { headers } from '@component/explorer/ui/ExplorerAddressMentions/constants';
import { useModal } from '@hooks/useModal';
import { useExplorerFindMentionsForAddressLazyQuery, useMeQuery } from '@apolloGenerated';
import { getNetworkEnum } from '@helpers/address';
import { formatDate } from '@utils/timestamp';
import { useTranslation } from 'react-i18next';
import styles from '../../../../screen/Transactions/transactions.module.scss';
import { hasPermission } from '@utils/auth';
import { SUBSCRIPTION_EXPLORER_MARKUP } from '@constants/permissions.const';
import { RiskDot } from '@component/NewRisk';
import sdk from '@aml/sdk';

interface Props {
  network: string;
  address: string;
}

export const ExplorerAddressMentions: FC<Props> = ({ network, address }) => {
  const { t } = useTranslation();
  const openTransactionModal = useModal('modalMarkupTariffs');
  const openViewDetails = useModal('modalViewDetails');

  const { data: user, loading: isLoadingUser } = useMeQuery({
    fetchPolicy: 'network-only',
  });

  const { data: categoryDict, isLoading: isLoadingCategory } =
    sdk.risk.BTC.queries.useRiskDictServiceCurrentDict();

  const [fetchMentions, { data, loading }] = useExplorerFindMentionsForAddressLazyQuery();

  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );

  useLayoutEffect(() => {
    if (expireDate) {
      fetchMentions({
        variables: {
          network: getNetworkEnum(network),
          address,
        },
      });
    }
  }, [expireDate, address]);

  const renderDate = (timestamp: string) => {
    return (
      <div>
        <Typography variant="body-14">{formatDate(timestamp, true)}</Typography>
        <Typography variant="body-12" color="on-secondary-2">
          {formatDate(timestamp, false)}
        </Typography>
      </div>
    );
  };

  const renderCategory = (itemNumber: number, color?: string) => {
    const findCategory = Object.values(categoryDict?.params || {})?.find(
      (item) => item.number === itemNumber,
    );

    return (
      <div className={styles['transactions__group-cell']}>
        <div className={styles['transactions__category']}>
          {color && <RiskDot risk={findCategory?.risk ? findCategory?.risk : 0.1} />}
          {findCategory?.name || ' '}
        </div>
      </div>
    );
  };

  const renderProofs = (
    reportId: string,
    isPaid: boolean = false,
    link: string,
    address: string,
    id: number,
  ) => {
    if (!isPaid && !reportId) {
      return link ? (
        <div className={styles['transactions__wrapper']}>
          <CopyHash hash={link} onClickHash={() => openAddress(link)} />
        </div>
      ) : (
        '-'
      );
    }

    if (user?.me.billing.markup < 1 || !hasPermission(SUBSCRIPTION_EXPLORER_MARKUP)) {
      return (
        <Button variant={'outlined'} onClick={openTransactionModal}>
          {t('titles.details')}
        </Button>
      );
    }

    if (
      (isPaid || reportId) &&
      user?.me.billing.markup > 0 &&
      hasPermission(SUBSCRIPTION_EXPLORER_MARKUP)
    ) {
      return (
        <Button
          variant={'outlined'}
          onClick={() => openViewDetails({ address, details: true, id })}
        >
          {t('titles.viewDetails')}
        </Button>
      );
    }
    return '-';
  };

  const openAddress = (sourceAddress: string) => {
    const url = sourceAddress.match(/^https?:/) ? sourceAddress : '//' + sourceAddress;

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderSourceAddressLink = (sourceAddress?: string) => {
    if (!sourceAddress?.length) return '';

    return (
      <div className={styles['transactions__wrapper']}>
        <CopyHash hash={sourceAddress} onClickHash={() => openAddress(sourceAddress)} />
      </div>
    );
  };

  const renderLabel = (text: string) => {
    return (
      <div className={styles['transactions__text']}>
        <span>{text}</span>
      </div>
    );
  };

  const list = useMemo(() => {
    const generate =
      data?.explorerFindMentionsForAddress.edge.map((item) => ({
        date: renderDate(item.createdAt),
        category: renderCategory(
          item.risk?.categoryNumber,
          item.risk?.color,
        ),
        source: item?.source?.name || 'MatchSystems Reported',
        source_address: renderSourceAddressLink(item.source.sourceAddress),
        label: renderLabel(item.label || '-'),
        proofs: renderProofs(
          item.reportId,
          item.isPaid,
          item.link,
          item?.address,
          item.id,
        ),
        isPaid: String(!!(item.isPaid || item.reportId)), // компонент таблицы не принимает булевое занчение
      })) || [];

    return generate.sort(
      (a, b) => a.source.localeCompare(b.source) || b.isPaid.localeCompare(a.isPaid),
    );
  }, [data?.explorerFindMentionsForAddress.edge, user?.me.billing]);
  return (
    <>
      <div
        className={styles['transactions__table-wrapper']}
        hidden={loading || isLoadingUser || isLoadingCategory}
      >
        <TableController headers={headers()} data={list || []} />
      </div>
      <LoaderOverlay show={loading || isLoadingUser || isLoadingCategory} />
    </>
  );
};

import { FC, MouseEvent, useState } from 'react';
import { stopEvent, TextField } from '@rubin-dev/goblin';
import { Meta } from '../../../../models';
import styles from './styles.module.scss';
import { useModal } from '@hooks/useModal';

export const GraphNameInput: FC = () => {
  const [graphName, setGraphName] = useState(Meta.graphName);
  const openRenameGraphModal = useModal('renameGraph');
  const handleClickInput = (e: MouseEvent) => {
    stopEvent(e);
    openRenameGraphModal({
      onSuccess: (val) => {
        setGraphName(val);
        Meta.graphName = val;
      },
      uuid: Meta.uuid,
      initName: graphName,
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={handleClickInput}>
      <TextField
        className={styles.graphNameInput}
        fullWidth
        placeholder={graphName}
        ellipsis
        autoComplete="off"
        readonly
      />
    </div>
  );
};

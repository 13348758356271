import { ACTIVE_COLOR, COLORS } from '../../const/address.const';
import { LinkItem } from '@graph/types';
import { AddressTransactionsFilterTxType, Maybe } from '@apolloGenerated';
import { LineType } from '@rubin-dev/octavius';
import { LinkService } from './LinkService';
import getReverseTxType from '../../libs/helpers/getReverseTxType';
import { TableType } from '@graph/types/table';
import { GraphLineAmount, LinkCustomColor, Transaction } from '@graph/modules';
import { Meta } from '@graph/models';

export type AmountType = {
  type: AddressTransactionsFilterTxType;
  value: number;
  txid: string;
};
export class Link {
  hash: string;
  target: string;
  source: string;
  amount: AmountType[] = [];
  color?: Maybe<LinkCustomColor>;
  link?: LinkItem;
  constructor(targetHash: string, sourceHash: string, amount?: AmountType[]) {
    this.hash = LinkService.generateLinkHash(sourceHash, targetHash);
    this.target = targetHash;
    this.source = sourceHash;
    if (amount) this.amount = amount;
  }

  addAmount(txid: string, value: number, type: AddressTransactionsFilterTxType) {
    this.amount = [...this.amount, { txid, value, type }];
  }

  private removeAmount(txid: string, type: AddressTransactionsFilterTxType) {
    this.amount = this.amount.filter((el) => !(el.txid === txid && el.type === type));
  }

  private checkActiveLink(hash: string): boolean {
    return this.source === hash || this.target === hash;
  }

  private getTotalAmount(amountItems: AmountType[]): number {
    return amountItems.reduce(
      (acc: number, currentValue: AmountType) => acc + currentValue.value,
      0,
    );
  }

  private getAmountElement(amount: number, isActive?: boolean): JSX.Element {
    return (
      <GraphLineAmount
        amount={amount}
        active={isActive}
        color={this.getLinkColor(!!isActive)}
        network={Meta.network}
      />
    );
  }

  private getAmountSlot(isActive?: boolean): Partial<LinkItem['label']> {
    if (!this.amount.length) return {};
    const sentAmount = this.amount.filter(
      (el) => el.type === AddressTransactionsFilterTxType.Sent,
    );
    const receivedAmount = this.amount.filter(
      (el) => el.type === AddressTransactionsFilterTxType.Receives,
    );
    if (receivedAmount.length && sentAmount.length)
      return {
        source: this.getAmountElement(this.getTotalAmount(receivedAmount), isActive),
        target: this.getAmountElement(this.getTotalAmount(sentAmount), isActive),
      };
    return {
      center: this.getAmountElement(
        this.getTotalAmount(receivedAmount.length ? receivedAmount : sentAmount),
        isActive,
      ),
    };
  }

  private getDirection(): LineType['direction'] {
    const receivesLines = this.amount.filter(
      (el) => el.type === AddressTransactionsFilterTxType.Receives,
    );
    const sentLines = this.amount.filter(
      (el) => el.type === AddressTransactionsFilterTxType.Sent,
    );
    if (receivesLines.length && sentLines.length) return 'all';
    if (sentLines.length) return 'target';
    return 'source';
  }

  private getLinkColor(isActive: boolean) {
    if (isActive) return ACTIVE_COLOR;
    if (this.color) return this.color;
    return COLORS.large;
  }

  setCustomColor(color: Link['color']) {
    this.color = color;
  }

  sumLink({ txid, amount, type, targetAddress, sourceAddress }: Transaction) {
    let realType = type;
    const isReverse = this.target !== targetAddress || this.source !== sourceAddress;
    if (isReverse) realType = getReverseTxType(type);
    this.addAmount(txid, +amount, realType);
  }

  minusLink({ sourceAddress, targetAddress, type, txid }: Transaction) {
    this.removeAmount(
      txid,
      this.source === targetAddress || this.target === sourceAddress
        ? getReverseTxType(type)
        : type,
    );
  }

  setLink(currentHash: string): void {
    const isActive = this.checkActiveLink(currentHash);
    this.link = {
      source: this.source as any,
      target: this.target as any,
      color: this.getLinkColor(isActive),
      label: this.getAmountSlot(isActive),
      direction: this.getDirection(),
      type: TableType.Transaction,
    };
  }

  activateLink(): void {
    this.link = {
      ...this.link,
      label: this.getAmountSlot(true),
      color: ACTIVE_COLOR,
    } as LinkItem;
  }

  checkCanDeleted(): boolean {
    return this.amount.length === 1;
  }
}

import { Link } from './Link';
import { LinkItem } from '../../types/graph';
import { LinkService } from './LinkService';

export class LinkList {
  static hashList: Record<string, Link> = {};

  static addLink(link: Link, rewrite?: boolean) {
    const linkHash = link.hash;
    if (LinkList.hashList[linkHash] && !rewrite) return;
    LinkList.hashList[link.hash] = link;
  }

  static removeLink(linkHash: string) {
    delete LinkList.hashList[linkHash];
    delete LinkList.hashList[linkHash.split(',').reverse().join()];
  }

  static getLinksForGraph(currentHash: string): LinkItem[] {
    const linksItems: Link[] = Object.values(LinkList.hashList);
    return linksItems.reduce<LinkItem[]>((acc, el) => {
      el.setLink(currentHash);
      if (currentHash === el.hash || el.hash.split(',').reverse().join() === currentHash)
        el.activateLink();
      if (el.link) acc.push(el.link);
      return acc;
    }, []);
  }

  static getLinkByHashes(target: string, source: string): Link {
    return (
      LinkList.hashList[LinkService.generateLinkHash(source, target)] ||
      LinkList.hashList[LinkService.generateLinkHash(target, source)]
    );
  }
}

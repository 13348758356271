import React, { FC, PropsWithChildren, useMemo } from 'react';
import { childrenWithProps } from '@utils/react';
import { Base, BaseProps } from '../Base/Base';
import { useMeQuery, User } from '@apolloGenerated';
import { LoaderOverlay } from '@rubin-dev/goblin';

interface LayoutUserProps {
  headerSlot?: JSX.Element;
  baseProps?: BaseProps;
}

export const LayoutUser: FC<PropsWithChildren<LayoutUserProps>> = ({
  children,
  headerSlot,
  baseProps,
}) => {
  const { loading, data } = useMeQuery();

  return (
    <Base user={data?.me} header={headerSlot} {...baseProps}>
      {childrenWithProps<{
        user?: Omit<User, 'permissions' | 'billing'>;
      }>({
        children,
        user: data?.me,
      })}
      <LoaderOverlay show={loading} />
    </Base>
  );
};
